import React, { useState, useEffect } from "react";
import { uploadFileToServer, retrievePriceDates, uploadIFTAFileToServer, retrievePriceSheetDetails } from "../helpers/priceFileHelper";
import { GoogleMap, LoadScript, Marker, InfoWindow } from "@react-google-maps/api";
import { FaCanadianMapleLeaf } from "react-icons/fa";
import { LiaFlagUsaSolid } from "react-icons/lia";
import styles from "./Fuel.module.css";

const Fuel = () => {
  const lib = ["places"];

  const center = {
    lat: 43.65107,
    lng: -90.347015,
  };

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [uploadMessage, setUploadMessage] = useState("");
  const [priceDates, setPriceDates] = useState({ usDates: [], caDates: [] });
  const [iftaFile, setIftaFile] = useState(null);
  const [iftaUploadMessage, setIftaUploadMessage] = useState("");
  const [selectedPriceSheet, setSelectedPriceSheet] = useState(null);
  const [desiredPrice, setDesiredPrice] = useState(3.0);
  const [priceMatrix, setPriceMatrix] = useState([]);
  const [selectedPriceType, setSelectedPriceType] = useState("Retail");
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedMarker, setSelectedMarker] = useState(null);

  const organizationInfo = JSON.parse(localStorage.getItem("organizationInfo"));
  const isAdmin = organizationInfo.email && organizationInfo.email.includes("@vrtta.green");

  const handleFileChange = (event) => {
    const files = event.target.files;
    setSelectedFiles(files);
  };

  const handleIfraFileChange = (event) => {
    const file = event.target.files[0];
    setIftaFile(file);
  };

  const handleUpload = async () => {
    if (selectedFiles.length === 0) {
      setUploadMessage("Please select files to upload.");
      return;
    }

    setLoading(true);
    setUploadMessage("");

    const results = [];
    for (let file of selectedFiles) {
      const response = await uploadFileToServer(file);
      results.push(response);
    }

    const failedUploads = results.filter((result) => result.status !== 200);
    if (failedUploads.length > 0) {
      setUploadMessage(`Failed to upload ${failedUploads.length} file(s).`);
    } else {
      setUploadMessage("Files uploaded successfully.");
    }

    setLoading(false);
  };

  const handleIFTAUpload = async () => {
    if (!iftaFile) {
      setIftaUploadMessage("Please select an IFTA file to upload.");
      return;
    }

    setLoading(true);
    setIftaUploadMessage("");

    const response = await uploadIFTAFileToServer(iftaFile);
    if (response.status !== 200) {
      setIftaUploadMessage(`Failed to upload IFTA file: ${response.message}`);
    } else {
      setIftaUploadMessage("IFTA file uploaded successfully.");
    }

    setLoading(false);
  };

  const handlePriceSheetSelect = async (filename) => {
    setSelectedDate(filename);
    setLoading(true);
    const response = await retrievePriceSheetDetails(filename);
    if (response.status === 200) {
      setSelectedPriceSheet(filename);
      setPriceMatrix(response.data.priceMatrix);

      const priceValues = response.data.priceMatrix.slice(1).map((row) => row[2]);
      const totalPrice = priceValues.reduce((acc, price) => acc + price, 0);
      const meanPrice = totalPrice / priceValues.length;

      setDesiredPrice(meanPrice);
      console.log(meanPrice);
    } else {
      setUploadMessage("Failed to fetch price sheet data.");
    }
    setLoading(false);
  };

  const handlePriceTypeToggle = (type) => {
    setSelectedPriceType(type);
  };

  const handleMarkerClick = (location) => {
    setSelectedMarker(location);
  };

  const handleInfoWindowCloseClick = () => {
    setSelectedMarker(null);
  };

  const getMarkerColor = (price) => {
    if (price < desiredPrice) return "green";
    if (price <= desiredPrice * 1.1) return "orange";
    return "red";
  };
  useEffect(() => {
    const fetchPriceDates = async () => {
      const response = await retrievePriceDates();
      if (response.status === 200) {
        const availableDates = response.data.availableDates || [];

        const usDates = availableDates
          .filter((date) => date.startsWith("US"))
          .sort((a, b) => {
            const dateA = new Date(a.split("_")[1]);
            const dateB = new Date(b.split("_")[1]);
            return dateB - dateA;
          });

        const caDates = availableDates
          .filter((date) => date.startsWith("CA"))
          .sort((a, b) => {
            const dateA = new Date(a.split("_")[1]);
            const dateB = new Date(b.split("_")[1]);
            return dateB - dateA;
          });

        setPriceDates({ usDates, caDates });
      }
    };
    fetchPriceDates();
  }, []);

  return (
    <div className={styles.fuelContainer}>
      <div className={styles.leftSection}>
        {isAdmin && (
          <div className={styles.section}>
            <div className={styles.title}>Upload IFTA Sheet</div>
            <div className={styles.field}>
              <label className={styles.label}>Select IFTA File:</label>
              <input type="file" className={styles.input} onChange={handleIfraFileChange} />
            </div>
            <div className={styles.actions}>
              <button className={styles.button} onClick={handleIFTAUpload} disabled={loading}>
                {loading ? "Uploading..." : "Upload IFTA File"}
              </button>
            </div>
            {iftaUploadMessage && <div className={styles.message}>{iftaUploadMessage}</div>}
          </div>
        )}
        <div className={styles.section}>
          <div className={styles.divider} />
          <div className={styles.title}>Upload Fuel Prices</div>
          <div className={styles.field}>
            <label className={styles.label}>Select Files:</label>
            <input type="file" className={styles.input} onChange={handleFileChange} multiple />
          </div>
          <div className={styles.actions}>
            <button className={styles.button} onClick={handleUpload} disabled={loading}>
              {loading ? "Uploading..." : "Upload Price Sheet"}
            </button>
          </div>
          {uploadMessage && <div className={styles.message}>{uploadMessage}</div>}
        </div>
        <div className={styles.divider} />
        <div className={styles.section}>
          <div className={styles.title}>Available Price Dates</div>
          {loading ? (
            <div>Loading...</div>
          ) : (
            <div className={styles.priceDateContainer}>
              <div className={styles.dateColumn}>
                <h3 style={{ color: "darkblue" }}>
                  {" "}
                  <LiaFlagUsaSolid size="30px" color="darkBlue" /> US Sheets
                </h3>
                {priceDates.usDates.length > 0 ? (
                  <ul className={styles.dateList}>
                    {priceDates.usDates.map((date, index) => (
                      <li key={index} onClick={() => handlePriceSheetSelect(date)} className={selectedDate === date ? styles.selected : ""}>
                        {date}
                      </li>
                    ))}
                  </ul>
                ) : (
                  <div>No US dates available.</div>
                )}
              </div>
              <div className={styles.dateColumn}>
                <h3 style={{ color: "red" }}>
                  <FaCanadianMapleLeaf size="25px" color="red" /> CA Sheets
                </h3>
                {priceDates.caDates.length > 0 ? (
                  <ul className={styles.dateList}>
                    {priceDates.caDates.map((date, index) => (
                      <li key={index} onClick={() => handlePriceSheetSelect(date)} className={selectedDate === date ? styles.selected : ""}>
                        {date}
                      </li>
                    ))}
                  </ul>
                ) : (
                  <div>No CA dates available.</div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      <div className={styles.rightSection}>
        <LoadScript googleMapsApiKey="AIzaSyCIN3a0V9O2BCx4O2ZRfa7iTOm5AjGspOw" libraries={lib}>
          <GoogleMap mapContainerStyle={{ width: "100%", height: "calc(100vh - 175px)" }} center={center} zoom={4}>
            {priceMatrix.map((location, index) => {
              if (index === 0) return null;

              const price = location[2];
              const color = getMarkerColor(price);

              return <Marker key={index} position={{ lat: location[3], lng: location[4] }} onClick={() => handleMarkerClick(location)} icon={{ url: `http://maps.google.com/mapfiles/ms/icons/${color}-dot.png` }} />;
            })}
            {selectedMarker && (
              <InfoWindow position={{ lat: selectedMarker[3], lng: selectedMarker[4] }} onCloseClick={handleInfoWindowCloseClick}>
                <div>
                  <p>ID: {selectedMarker[0]}</p>
                  <p>Latitude: {selectedMarker[3]}</p>
                  <p>Longitude: {selectedMarker[4]}</p>
                  <p>Retail Price: ${selectedMarker[1].toFixed(4)}</p>
                  <p>Your Price ${selectedMarker[2].toFixed(4)}</p>
                </div>
              </InfoWindow>
            )}
          </GoogleMap>
        </LoadScript>
      </div>
    </div>
  );
};

export default Fuel;
