import { useState, useEffect } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { MdDelete } from "react-icons/md";
import { HiViewColumns } from "react-icons/hi2";

import styles from "./DispatchedRoutes.module.css";

import { getRoutesByStatus, deleteRoute, assignRoute } from "./helpers/routeHelper";
import { findGaddiGoogleRoute } from "./helpers/gaddiRouteHelper";
import { getOrgId } from "../utils/costUtils/getOrganizationId";
//import Spinner from "../../components/cost/shared/Spinner";
import Header from "../app_components/Header";
import RouteFetcher from "./routes-components/FetchRoutes";
import ConfirmModal from "./routes-components/ConfirmModal";
import ConfigureButtonDropdown from "./routes-components/ConfigureButtonDropdown";
import RouteGrid from "./routes-components/GridTable";
import AddRoutes from "./routes-components/AddRoutes";
import { retrievePriceDates } from "./helpers/priceFileHelper";
import withAuth from "./shared/withAuth";
import Invoices from "./routes-components/Invoices";

const DispatchedRoutes = () => {
  const [dispatchedRoutes, setDispatchedRoutes] = useState([]);
  const [calculatedRoutes, setCalculatedRoutes] = useState([]);
  const [assignedRoutes, setAssignedRoutes] = useState([]);

  const [selectedRoute, setSelectedRoute] = useState(null);
  const [selectedRoutes, setSelectedRoutes] = useState([]);
  const [selectedTab, setSelectedTab] = useState(0);
  const [configuration, setConfiguration] = useState({ selectedDate: "", priceCol: "Your Price", radius: 200000 });

  const [showColumnSelector, setShowColumnSelector] = useState(false);
  const [fileNames, setFileNames] = useState([]);

  const fetchFileNames = async () => {
    try {
      const response = await retrievePriceDates();
      if (response.status === 200) {
        setFileNames(response.data.availableDates);
      } else {
        console.error(`Error fetching file names: ${response.message}`);
      }
    } catch (error) {
      console.error(`Error fetching file names: ${error.message}`);
    }
  };

  useEffect(() => {
    fetchFileNames();
  }, []);

  const [loading, setLoading] = useState(true);
  const [refetch, setRefetch] = useState(false);

  const [headersByTab, setHeadersByTab] = useState([
    [
      { headerText: "Source", label: "startAddress" },
      { headerText: "Destination", label: "endAddress" },
      { headerText: "Dispatch Date", label: "dispatchDate", hidden: true },
      { headerText: "Delivery Date", label: "deliveryDate", hidden: true },
      { headerText: "Vehicle No.", label: "vehicleNo", hidden: true },
      { headerText: "Driver Name", label: "driverName", hidden: true },
      { headerText: "Driver Mobile", label: "driverMobile", hidden: true },
      { headerText: "Invoice No.", label: "invoiceNo", hidden: true },

      { headerText: "Weight", label: "loadWeight", hidden: true },
      { headerText: "Min Reserve (gal)", label: "minReserve" },
      { headerText: "End Reserve (gal)", label: "endReserve" },
      { headerText: "Stops", label: "maxStops" },
      { headerText: "Fuel Eco (MPG)", label: "fuelEconomy" },
      { headerText: "Current Gas (gal)", label: "currentGas" },
      { headerText: "Tank Capacity (gal)", label: "tankCapacity" },
      { headerText: "Radius (m)", label: "radius", hidden: true },
      { headerText: "Prices Date", label: "priceDate" },
    ],
    [
      { headerText: "Destination", label: "endAddress" },
      { headerText: "Dispatch Date", label: "dispatchDate", hidden: true },
      { headerText: "Delivery Date", label: "deliveryDate", hidden: true },
      { headerText: "Fuel Eco (mpg)", label: "fuelEconomy" },
      { headerText: "Current Gas (gal)", label: "currentGas" },
      { headerText: "Route Length (miles)", label: "routeLength" },
      { headerText: "Total Cost (USD)", label: "totalCost" },
      { headerText: "Total Gas (gal)", label: "totalGas" },
      { headerText: "IFTA (USD)", label: "IFTA" },
      { headerText: "GHG (kg CO2e)", label: "TTGHG" },
      { headerText: "Min Reserve (gal)", label: "minReserve", hidden: true },
      { headerText: "End Reserve (gal)", label: "endReserve", hidden: true },
      { headerText: "Tank Capacity (gal)", label: "tankCapacity", hidden: true },
      { headerText: "Price Date", label: "priceDate" },
    ],
    [
      { headerText: "Source", label: "startAddress" },
      { headerText: "Destination", label: "endAddress" },
      { headerText: "Dispatch Date", label: "dispatchDate", hidden: true },
      { headerText: "Delivery Date", label: "deliveryDate" },
      { headerText: "Vehicle No.", label: "vehicleNo" },
      { headerText: "Driver Name", label: "driverName" },
      { headerText: "Driver Mobile", label: "driverMobile", hidden: true },
      { headerText: "Route Length", label: "routeLength" },
      { headerText: "Total Gas (gal)", label: "totalGas" },
      { headerText: "IFTA", label: "IFTA" },
      { headerText: "TTGHG", label: "TTGHG" },
      { headerText: "Price Date", label: "priceDate" },
    ],
  ]);

  useEffect(() => {
    const fetchDispatchedRoutes = async () => {
      setLoading(true);
      try {
        const response = await getRoutesByStatus("dispatched", 1, 1000);
        const updatedRoutes = response.data.routes.map((route) => ({
          ...route,
          maxStops: 3,
          minReserve: 50,
          endReserve: 50,
          priceDate: fileNames[fileNames.length - 1],
        }));

        const storedTrialRoutes = JSON.parse(localStorage.getItem("dispatchedRoutes")) || [];
        const storedTrialCalculatedRoutes = JSON.parse(localStorage.getItem("calculatedRoutes")) || [];

        const updatedStoredTrialRoutes = storedTrialRoutes.map((route) => ({
          ...route,
          priceDate: route.priceDate || fileNames[fileNames.length - 1],
        }));

        setDispatchedRoutes(() => [...updatedRoutes, ...updatedStoredTrialRoutes]);
        setCalculatedRoutes(() => [...storedTrialCalculatedRoutes]);
      } catch (error) {
        console.error("Error fetching routes:", error);
      } finally {
        setLoading(false);
      }
    };

    const fetchAssignedRoutes = async () => {
      setLoading(true);
      try {
        const response = await getRoutesByStatus("assigned", 1, 1000);
        const updatedRoutes = response.data.routes.map((route) => ({
          ...route,
          maxStops: 3,
          minReserve: 50,
          endReserve: 50,
        }));
        setAssignedRoutes(updatedRoutes);
      } catch (error) {
        console.error("Error fetching routes:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchDispatchedRoutes();
    fetchAssignedRoutes();
  }, [refetch, fileNames]);

  const dataByTab = [dispatchedRoutes, calculatedRoutes, assignedRoutes];

  const toggleColumnVisibility = (label) => {
    const updatedHeaders = [...headersByTab[selectedTab]];
    const headerIndex = updatedHeaders.findIndex((header) => header.label === label);

    if (headerIndex !== -1) {
      const header = updatedHeaders[headerIndex];

      if (!header.hidden) {
        updatedHeaders[headerIndex] = { ...header, hidden: true };
      } else {
        updatedHeaders[headerIndex] = { ...header };
        delete updatedHeaders[headerIndex].hidden;
      }

      const newHeadersByTab = [...headersByTab];
      newHeadersByTab[selectedTab] = updatedHeaders;
      setHeadersByTab(newHeadersByTab);
    }
  };

  const handleTabSelect = (index) => {
    setSelectedTab(index);
  };

  const fetchRouteData = async (route) => {
    if (!route) return;

    try {
      const requestData = {
        organizationId: getOrgId(),
        mpg: route.fuelEconomy,
        start_gas: route.currentGas,
        min_reserve: route.minReserve,
        end_reserve: route.endReserve,
        max_gas: route.tankCapacity,
        max_stops: route.maxStops,
        radius: route.radius,
        price_col: configuration.priceCol,
        waypoints: [],
        date: route.priceDate,
        google_url: route.googleLink,
      };

      console.log("requestData", requestData);

      // Helper function to fetch matrix data for the given stops
      const result = await findGaddiGoogleRoute(requestData);
      return result.data || {};
    } catch (error) {
      console.error(`Error fetching route data for: ${error.message}`);
      return {};
    }
  };

  const handleCalculateSelection = async () => {
    const updatedDispatchedRoutes = [...dispatchedRoutes];
    const updatedCalculatedRoutes = [...calculatedRoutes];
    for (const route of selectedRoutes) {
      if (route) {
        const routeIndex = updatedDispatchedRoutes.findIndex((r) => r.startAddress === route.startAddress && r.endAddress === route.endAddress && r.googleLink === route.googleLink);
        if (routeIndex !== -1) {
          updatedDispatchedRoutes[routeIndex] = {
            ...updatedDispatchedRoutes[routeIndex],
            calculationStatus: "queued",
          };
        }
        setDispatchedRoutes([...updatedDispatchedRoutes]);
      }
    }
    for (const route of selectedRoutes) {
      if (route) {
        const routeIndex = updatedDispatchedRoutes.findIndex((r) => r.startAddress === route.startAddress && r.endAddress === route.endAddress && r.googleLink === route.googleLink);

        updatedDispatchedRoutes[routeIndex] = {
          ...updatedDispatchedRoutes[routeIndex],
          calculationStatus: "in-progress",
        };
        setDispatchedRoutes([...updatedDispatchedRoutes]);

        try {
          const response = await fetchRouteData(route);

          if (response.success) {
            const updatedRoute = {
              ...route,
              TTGHG: response.TTGHG,
              IFTA: response.ifta_cost,
              totalCost: response.total_cost,
              totalGas: response.total_gallons,
              routeLength: response.total_trip_distance,
              assignedRoute: response,
              calculationStatus: "success",
              error: false,
            };

            updatedDispatchedRoutes.splice(routeIndex, 1);
            updatedCalculatedRoutes.push(updatedRoute);

            setDispatchedRoutes([...updatedDispatchedRoutes]);
            setCalculatedRoutes([...updatedCalculatedRoutes]);

            if (updatedRoute.status === "trial") {
              const trialCalculatedRoutes = JSON.parse(localStorage.getItem("calculatedRoutes")) || [];
              localStorage.setItem("calculatedRoutes", JSON.stringify([...trialCalculatedRoutes, updatedRoute]));
            }
            localStorage.setItem("dispatchedRoutes", JSON.stringify(updatedDispatchedRoutes));
          } else {
            updatedDispatchedRoutes[routeIndex] = {
              ...updatedDispatchedRoutes[routeIndex],
              calculationStatus: "failed",
              error: true,
            };
            setDispatchedRoutes([...updatedDispatchedRoutes]);
          }
        } catch (error) {
          console.error(`Error fetching data for routeId: ${route.startAddress}`, error);
          if (routeIndex !== -1) {
            updatedDispatchedRoutes[routeIndex] = {
              ...updatedDispatchedRoutes[routeIndex],
              calculationStatus: "failed",
            };
          }
          setDispatchedRoutes([...updatedDispatchedRoutes]);
        }
      }
    }

    setSelectedRoutes([]);
  };

  const handleDuplicateRoutes = () => {
    if (selectedRoutes.length === 0) return;

    const updatedRoutes = [...dispatchedRoutes];

    for (let i = updatedRoutes.length - 1; i >= 0; i--) {
      const currentRoute = updatedRoutes[i];
      if (selectedRoutes.includes(currentRoute)) {
        const duplicatedRoute = {
          ...currentRoute,
          isDuplicate: true,
        };
        updatedRoutes.splice(i + 1, 0, duplicatedRoute);
      }
    }

    setDispatchedRoutes(updatedRoutes);
  };

  const handleDeleteRoutes = async () => {
    setLoading(true);

    const isTrialRoute = selectedRoutes.every((route) => route.status === "trial");

    if (isTrialRoute) {
      const updatedDispatchedRoutes = dispatchedRoutes.filter((route) => !selectedRoutes.includes(route));
      const updatedCalculatedRoutes = calculatedRoutes.filter((route) => !selectedRoutes.includes(route));

      setDispatchedRoutes(updatedDispatchedRoutes);
      setCalculatedRoutes(updatedCalculatedRoutes);

      localStorage.setItem("dispatchedRoutes", JSON.stringify(updatedDispatchedRoutes));
      localStorage.setItem("calculatedRoutes", JSON.stringify(updatedCalculatedRoutes));

      setSelectedRoutes([]);
      setLoading(false);
      return;
    }

    const deletionPromises = selectedRoutes.map((route) => deleteRoute(route.routeId));

    try {
      const results = await Promise.all(deletionPromises);

      const allSuccessful = results.every((result) => result.status === 200);

      if (!allSuccessful) {
        results.forEach((result) => {
          if (result.status !== 200) {
            console.error("Error deleting route:", result.message);
          }
        });
      }
    } catch (error) {
      console.error("Error deleting routes:", error);
    } finally {
      setSelectedRoutes([]);
      setRefetch((prev) => !prev);
      setLoading(false);
    }
  };

  const handleApproveRoute = async (routeId, selectedOption) => {
    if (!selectedOption) return;

    try {
      // Assign the selected route
      const response = await assignRoute(routeId, {
        assignedRoute: selectedOption,
        routeLength: selectedOption.total_trip_distance,
        totalGas: selectedOption.total_gallons,
        totalCost: selectedOption.total_cost,
        IFTA: selectedOption.ifta_cost,
        TTGHG: selectedOption.TTGHG,
      });

      if (response.status === 200) {
        const updatedCalculatedRoutes = calculatedRoutes.filter((route) => route.routeId !== selectedRoute.routeId);

        setCalculatedRoutes(updatedCalculatedRoutes);

        setRefetch((prev) => !prev);
      } else {
        console.error(`Error saving route: ${response.message}`);
      }
    } catch (error) {
      console.error(`Error saving route: ${error.message}`);
    }
  };

  const handleSelectedRoutes = (selectedRows) => {
    setSelectedRoutes(selectedRows);
    if (selectedRows.length === 1) {
      setSelectedRoute(selectedRows[0]);
    } else {
      setSelectedRoute(null);
    }
  };

  const handleUpdateRouteAttributes = (selectedRows, updates) => {
    console.log("selectedRows", selectedRows);
    const updatedRoutes = dispatchedRoutes.map((route) => {
      if (selectedRows.some((selected) => selected.startAddress === route.startAddress && selected.endAddress === route.endAddress && selected.googleLink === route.googleLink)) {
        return {
          ...route,
          ...(updates.minReserve && { minReserve: updates.minReserve }),
          ...(updates.endReserve && { endReserve: updates.endReserve }),
          ...(updates.maxStops && { maxStops: updates.maxStops }),
          ...(updates.priceDate !== "" && { priceDate: updates.priceDate }),
          ...(updates.radius && { radius: updates.radius }),
        };
      }
      return route;
    });

    setDispatchedRoutes(updatedRoutes);
  };

  // Function to handle custom Google link update
  const handleCustomGoogleLinkUpdate = (updatedRoute) => {
    const updatedRoutes = dispatchedRoutes.map((route) => {
      if (route.routeId === updatedRoute.routeId) {
        return { ...route, googleLink: updatedRoute.googleLink };
      }
      return route;
    });
    setDispatchedRoutes(updatedRoutes);
  };

  const handleAddTrialRoutes = (newRoutes) => {
    const updatedRoutes = newRoutes.map((route) => ({
      ...route,
      radius: 200000,
      priceDate: fileNames[fileNames.length - 1],
    }));

    localStorage.setItem("dispatchedRoutes", JSON.stringify(updatedRoutes));
    setDispatchedRoutes((prevRoutes) => [...updatedRoutes, ...prevRoutes]);
  };

  const unassignRoute = (routesToUnassign) => {
    const updatedCalculatedRoutes = calculatedRoutes.filter((route) => !routesToUnassign.some((unassign) => route.googleLink === unassign.googleLink));

    const updatedDispatchedRoutes = [...dispatchedRoutes, ...routesToUnassign.map((route) => ({ ...route, calculationStatus: undefined }))];

    setCalculatedRoutes(updatedCalculatedRoutes);
    setDispatchedRoutes(updatedDispatchedRoutes);

    const storedDispatchedRoutes = JSON.parse(localStorage.getItem("dispatchedRoutes")) || [];
    const storedCalculatedRoutes = JSON.parse(localStorage.getItem("calculatedRoutes")) || [];

    const updatedStoredCalculatedRoutes = storedCalculatedRoutes.filter((route) => !routesToUnassign.some((unassign) => route.startAddress === unassign.startAddress));

    const updatedStoredDispatchedRoutes = [...storedDispatchedRoutes, ...routesToUnassign.map((route) => ({ ...route, calculationStatus: undefined }))];

    localStorage.setItem("dispatchedRoutes", JSON.stringify(updatedStoredDispatchedRoutes));
    localStorage.setItem("calculatedRoutes", JSON.stringify(updatedStoredCalculatedRoutes));
  };

  return (
    <div className={styles.dispatchContainer}>
      <Header selectedLanguage="en" />
      <div className={styles.routesOverview}>
        <>
          <Tabs selectedIndex={selectedTab} onSelect={handleTabSelect}>
            <div className={styles.headerContainer}>
              <TabList className={styles.tablist}>
                <Tab className={`${styles.tab} ${selectedTab === 0 ? styles.selected : ""}`}>
                  <div className={styles.tabContent}>Dispatched Routes ({dispatchedRoutes && dispatchedRoutes.length})</div>
                </Tab>
                <span className={styles.tabDivider}>|</span>
                <Tab className={`${styles.tab} ${selectedTab === 1 ? styles.selected : ""}`}>
                  <div className={styles.tabContent}>Calculated Routes ({calculatedRoutes && calculatedRoutes.length})</div>
                </Tab>
                <span className={styles.tabDivider}>|</span>
                <Tab className={`${styles.tab} ${selectedTab === 2 ? styles.selected : ""}`}>
                  <div className={styles.tabContent}>Assigned Routes ({assignedRoutes && assignedRoutes.length})</div>
                </Tab>{" "}
                <span className={styles.tabDivider}>|</span>
                <Tab className={`${styles.tab} ${selectedTab === 2 ? styles.selected : ""}`}>
                  <div className={styles.tabContent}>Invoices </div>
                </Tab>
              </TabList>{" "}
              <div className={styles.buttonContainer}>
                <AddRoutes onAddTrialRoutes={handleAddTrialRoutes} />
                <ConfigureButtonDropdown configuration={configuration} setConfiguration={setConfiguration} handleUpdateRouteAttributes={handleUpdateRouteAttributes} selectedRows={selectedRoutes} routeData={dispatchedRoutes} />
                {selectedRoutes.length > 0 ? (
                  <>
                    <ConfirmModal title="Delete Routes" text="Are you sure you want to delete the selected routes?" onConfirm={handleDeleteRoutes} iconButton={() => <MdDelete size={"25px"} color="grey" />} />
                  </>
                ) : (
                  <RouteFetcher onUpload={() => setRefetch((prev) => !prev)} />
                )}{" "}
                <button onClick={() => setShowColumnSelector(!showColumnSelector)} className={styles.columnSelectorButton}>
                  <HiViewColumns size={"25px"} color="grey" />
                </button>
                {showColumnSelector && (
                  <div className={styles.columnSelectorDropdown}>
                    {headersByTab[selectedTab].map((header) => (
                      <label key={header.label}>
                        <input type="checkbox" checked={!header.hidden} onChange={() => toggleColumnVisibility(header.label)} />
                        {header.headerText}
                      </label>
                    ))}
                  </div>
                )}
              </div>
            </div>
            {dataByTab.map((routes, index) => (
              <TabPanel key={index}>
                <div className={styles.tableContainer}>
                  <RouteGrid headerData={headersByTab[selectedTab]} routeData={dataByTab[selectedTab]} handleSelectedRoutes={handleSelectedRoutes} handleCalculateSelection={handleCalculateSelection} handleApproveRoute={handleApproveRoute} handleUpdateRouteAttributes={handleUpdateRouteAttributes} handleCustomGoogleLinkUpdate={handleCustomGoogleLinkUpdate} unassignRoute={unassignRoute} handleDuplicateRoutes={handleDuplicateRoutes} index={index} />
                </div>
              </TabPanel>
            ))}
            <TabPanel>
              <Invoices />
            </TabPanel>
          </Tabs>
          <div className={styles.tableWrapper}></div>{" "}
        </>
      </div>
    </div>
  );
};

export default withAuth(DispatchedRoutes);
