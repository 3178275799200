import axios from "axios";
import { serverConfig } from "./serverConfig";

// Create a vehicle
export const createVehicle = async (requestData) => {
  const token = localStorage.getItem("jwtToken");

  const options = {
    method: "POST",
    url: `${serverConfig.serverAddress}/vehicle/create`,
    data: requestData,
    headers: {
      "Content-Type": "application/json",
      "api-key": serverConfig.apiKey,
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const response = await axios(options);
    return {
      status: response.status,
      message: response.data.message || response.statusText,
      data: response.data,
    };
  } catch (error) {
    return {
      status: error.response?.status || 500,
      message: error.response?.data?.message || error.response?.statusText || "Failed to create vehicle",
    };
  }
};

// Edit a vehicle
export const editVehicle = async (vehicleId, requestData) => {
  const token = localStorage.getItem("jwtToken");

  const options = {
    method: "PUT",
    url: `${serverConfig.serverAddress}/vehicle/${vehicleId}/edit`,
    data: requestData,
    headers: {
      "Content-Type": "application/json",
      "api-key": serverConfig.apiKey,
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const response = await axios(options);
    return {
      status: response.status,
      message: response.data.message || response.statusText,
      data: response.data,
    };
  } catch (error) {
    return {
      status: error.response?.status || 500,
      message: error.response?.data?.message || error.response?.statusText || "Failed to edit vehicle",
    };
  }
};

// Delete a vehicle
export const deleteVehicle = async (vehicleId) => {
  const token = localStorage.getItem("jwtToken");

  const options = {
    method: "DELETE",
    url: `${serverConfig.serverAddress}/vehicle/${vehicleId}/delete`,
    headers: {
      "api-key": serverConfig.apiKey,
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const response = await axios(options);
    return {
      status: response.status,
      message: response.data.message || response.statusText,
      data: response.data,
    };
  } catch (error) {
    return {
      status: error.response?.status || 500,
      message: error.response?.data?.message || error.response?.statusText || "Failed to delete vehicle",
    };
  }
};

// Get all vehicles
export const getAllVehicles = async () => {
  const token = localStorage.getItem("jwtToken");

  const options = {
    method: "GET",
    url: `${serverConfig.serverAddress}/vehicle/get`,
    headers: {
      "api-key": serverConfig.apiKey,
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const response = await axios(options);
    return {
      status: response.status,
      message: response.data.message || response.statusText,
      data: response.data,
    };
  } catch (error) {
    return {
      status: error.response?.status || 500,
      message: error.response?.data?.message || error.response?.statusText || "Failed to fetch vehicles",
    };
  }
};
