import React, {useEffect, useState} from 'react';
import '../style/components-style/OrganizationalLCA.css';
import {Icon} from '@iconify/react';
import config from '../config';
import {translations} from '../app_components/Translation';
import {useAuth} from "../authentication/AuthProvider";

const staticReferences = ['ISO14025: 2006-Environmental labels and declarations -Type III environmental declarations -Principles and procedures', 'ISO14040: 2006-Environmental management- Life cycle assessment -Principles and framework', 'ISO14044: 2006/Amd 2: 2020 -Environmental management -Life cycle assessment -Requirements and guidelines', 'ISO21930: 2017 Sustainability in buildings and civil engineering works -Core rules for environmental product declarations of construction products and services', 'Part A: Life Cycle Assessment Calculation Rules and Report Requirements UL Environment (December 2018, version 3.2)', 'Part B: Concrete Masonry and Segmental Concrete Paving Product EPD Requirements ULEnvironment (November 2020, v1.0)',];

const categoryToMethodologyMap = {
    'Stationary Combustion': 'https://www.epa.gov/sites/default/files/2020-12/documents/stationaryemissions.pdf',
    'Mobile Sources': 'https://www.epa.gov/sites/default/files/2020-12/documents/mobileemissions.pdf',
    'Refrigeration / AC Equipment Use': 'https://www.epa.gov/sites/default/files/2020-12/documents/mobileemissions.pdf',
    'Fire Suppression': 'https://www.epa.gov/climateleadership/ghg-emission-factors-hub',
    'Purchased Gases': 'https://www.epa.gov/climateleadership/ghg-emission-factors-hub',
    'Electricity': 'https://www.epa.gov/sites/default/files/2020-12/documents/electricityemissions.pdf',
    'Steam': 'https://www.epa.gov/sites/default/files/2020-12/documents/electricityemissions.pdf',
    'Business Travel': 'https://ghgprotocol.org/standards/scope-3-standard',
    'Employee Commuting': 'https://ghgprotocol.org/standards/scope-3-standard',
    'Upstream Transportation and Distribution': 'https://www.epa.gov/climateleadership/scope-3-inventory-guidance',
    'Downstream Transportation and Distribution': 'https://www.epa.gov/climateleadership/scope-3-inventory-guidance',
    'Purchased Goods and Services': 'https://ghgprotocol.org/standards/scope-3-standard',
    'Capital Goods': 'https://ghgprotocol.org/standards/scope-3-standard',
    'Fuel and Energy-related Activities': 'https://ghgprotocol.org/standards/scope-3-standard',
    'Processing of Sold Products': 'https://ghgprotocol.org/standards/scope-3-standard',
    'Use of Sold Products': 'https://ghgprotocol.org/standards/scope-3-standard',
    'End-of-life Treatment of Sold Products': 'https://ghgprotocol.org/standards/scope-3-standard',
    'Waste': 'https://ghgprotocol.org/standards/scope-3-standard',
    'Franchises': 'https://ghgprotocol.org/standards/scope-3-standard',
    'Investments': 'https://ghgprotocol.org/standards/scope-3-standard',
    'Upstream Leased Assets': 'https://www.epa.gov/sites/default/files/2020-12/documents/stationaryemissions.pdf',
    'Downstream Leased Assets': 'https://www.epa.gov/sites/default/files/2020-12/documents/stationaryemissions.pdf',
};

const organizationalBoundariesDescription = "Which consolidation approach was chosen (check each consolidation " +
    "approach for which your company is reporting emissions.) If your company is reporting according to more than one " +
    "consolidation approach, please complete and attach an additional completed reporting template that provides your " +
    "company’s emissions data following the other consolidation approach(es)";

const operationalBoundariesScope3 = 'Are Scope 3 emissions included in this inventory?';

const operationalBoundariesCO2 = 'Direct CO2 emissions from Biogenic combustion (mtCO2)';

const baseYearLine1 = 'Year chosen as base year';

const baseYearLine2 = 'Clarification of company-determined policy for making base year emissions recalculations';

const baseYearLine3 = 'Context for any significant emissions changes that trigger base year emissions recalculations';

const formattedStaticReferences = staticReferences.map(item => `${item}`).join('\n');

const defaultGHGInfo = {
    organizationalBoundaries: '',
    operationalBoundariesScope3: '',
    operationalBoundariesCO2: '',
    baseYearLine1: '',
    baseYearLine2: '',
    baseYearLine3: '',
    assumption: '',
    exclusion: '',
    formattedEmissions: '',
    formattedReferences: '',
}

function OrganizationBottomLCA({selectedRow, selectedLanguage, setLCAData}) {
    const [isOpen, setIsOpen] = useState(true);
    const [emissions, setEmissions] = useState([]);
    const [emissionDetails, setEmissionDetails] = useState([]);
    const [emissionInfo, setEmissionInfo] = useState([]);
    const [editableGHGInfo, setEditableGHGInfo] = useState({});
    const [isHovered, setIsHovered] = useState(false);

    const selectedText = translations[selectedLanguage]?.lifeCycleAssessment || {};

    const {user} = useAuth();
    const userId = user ? user.username : null;

    const toggleContainer = () => {
        setIsOpen(!isOpen);
    };

    const handleChange = (field, value) => {
        setEditableGHGInfo(prev => ({
            ...prev, [field]: value
        }));
    };

    const handleSelectChange = (event, field) => {
        setEditableGHGInfo(prev => ({
            ...prev, [field]: event.target.value
        }));
    };

    const textArea = (content, field, isReadOnly = false) => (<textarea
        className={isReadOnly ? 'readonly-ghg-textarea' : 'editable-ghg-textarea'}
        value={editableGHGInfo[field] || content}
        onChange={(e) => !isReadOnly && handleChange(field, e.target.value)}
        readOnly={isReadOnly}
    />);

    const fetchGHGInfo = async (templateId) => {
        try {
            const response = await fetch(`${config.apiUrl}/fetch_data`, {
                method: "POST",
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify({
                    table_name: "GHG_assessment_info",
                    condition: `template_id = '${templateId}'`,
                }),
            });
            const ghgInfoData = await response.json();

            // If no data is found, insert a new row
            if (!ghgInfoData || ghgInfoData.length === 0) {
                await addGHGInfo(templateId);
            }

            return ghgInfoData;
        } catch (error) {
            console.error("Error fetching LCA info:", error);
            return null;
        }
    };

    const addGHGInfo = async (templateId) => {
        try {
            const apiUrl = `${config.apiUrl}/add_data`;
            const body = JSON.stringify({
                table_name: "GHG_assessment_info",
                data: {
                    template_id: templateId,
                    user_id: userId,
                    organizational_boundaries: '',
                    scope3_emission: '',
                    co2_biogenic_emission: '',
                    base_year: '',
                    clarification: '',
                    context: '',
                    methodology: '',
                    env_references: ''
                },
            });

            const response = await fetch(apiUrl, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: body,
            });

            const result = await response.json();
            if (result.status !== "success") {
                console.error("Failed to add new LCA info:", result.message);
            }
        } catch (error) {
            console.error("Error adding new LCA info:", error);
        }
    };

    const loadGHGInfo = async () => {
        if (!selectedRow || !selectedRow.id) return;

        const ghgInfoData = await fetchGHGInfo(selectedRow.id);

        if (ghgInfoData && ghgInfoData.length > 0) {
            const data = ghgInfoData[0];

            const fetchedGHGInfo = {
                organizationalBoundaries: data.organizational_boundaries || '',
                operationalBoundariesScope3: data.scope3_emission || '',
                operationalBoundariesCO2: data.co2_biogenic_emission || '',
                baseYearLine1: data.base_year || '',
                baseYearLine2: data.clarification || '',
                baseYearLine3: data.context || '',
                assumption: data.assumption || '',
                exclusion: data.exclusion || '',
                formattedEmissions: data.methodology || '',
                formattedReferences: data.env_references || '',
            };
            setEditableGHGInfo(fetchedGHGInfo);
            setLCAData(fetchedGHGInfo);
        }
    };

    useEffect(() => {
        loadGHGInfo();
    }, [selectedRow]);

    useEffect(() => {
        if (emissionDetails.length && emissions.length && emissionInfo.length) {
            setEditableGHGInfo((prevData) => ({
                ...prevData,
                formattedEmissions: formatEmissions(emissions),
                formattedReferences: formatReferences(emissionDetails),
            }));

            setLCAData((prevData) => ({
                ...prevData,
                formattedEmissions: formatEmissions(emissions),
                formattedReferences: formatReferences(emissionDetails),
            }));
        }
    }, [emissions, emissionDetails, emissionInfo]);

    useEffect(() => {
        if (!selectedRow) {
            setEditableGHGInfo(defaultGHGInfo);
            setLCAData(defaultGHGInfo);
        } else {
            const fetchData = async () => {
                try {
                    const response = await fetch(`${config.apiUrl}/fetch_data`, {
                        method: "POST",
                        headers: {"Content-Type": "application/json"},
                        body: JSON.stringify({
                            table_name: 'emissions',
                            condition: `product_service_id = '${selectedRow.id}' OR organization_id = '${selectedRow.id}' OR corporate_id = '${selectedRow.id}'`,
                        }),
                    });
                    const emissions = await response.json();

                    setEmissions(emissions)

                    const emissionDetailPromises = emissions.map(emission => fetch(`${config.apiUrl}/api/get-emission-details-by-id?scope_id=${encodeURIComponent(emission.id)}`, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    })
                        .then(res => res.json()));

                    const emissionDetailResults = await Promise.all(emissionDetailPromises);
                    const emissionDetailsData = emissionDetailResults.flat();
                    setEmissionDetails(emissionDetailsData);

                    const gwpResponse = await fetch(`${config.apiUrl}/fetch_data`, {
                        method: 'POST', headers: {
                            'Content-Type': 'application/json'
                        }, body: JSON.stringify({
                            table_name: 'emission_info',
                        })
                    });
                    const emissionInfoData = await gwpResponse.json();

                    setEmissionInfo(emissionInfoData)
                } catch (error) {
                    console.error("Error fetching data:", error);
                }
            };
            fetchData();
        }
    }, [selectedRow]);


    const findReference = (type, category, description) => {
        const match = emissionInfo.find(info => info.type === type && info.category === category && info.description === description);
        return match ? match.reference : 'No reference found';
    };

    const formatEmissions = (emissions) => {
        return emissions.map(emission => {
            const methodology = categoryToMethodologyMap[emission.category]

            return `Emission Category: ${emission.category || 'N/A'}, Methodology: ${methodology}`;
        })
            .filter(Boolean).join('\n');
    }

    const formatReferences = (emissionDetails) => {
        const formattedEmissionDetails = emissionDetails
            .map(ed => {
                const emission = emissions.find(e => e.id === ed.emission_id) || {};
                const reference = findReference(ed.type, ed.category, ed.description);

                return `Emission Category: ${emission.category || 'N/A'}, Type: ${ed.type}, Category: ${ed.category}, Description: ${ed.description}, Reference: ${reference}`;
            })
            .filter(Boolean)
            .join('\n');
        return [...staticReferences, formattedEmissionDetails].map(item => `${item}`).join('\n');
    }

    const escapeSingleQuotes = (str) => {
        return str.replace(/'/g, "\\'");
    };

    const updateGHGInfo = async () => {
        if (userId) {
            try {
                const apiUrl = `${config.apiUrl}/update_data`;
                const body = JSON.stringify({
                    table_name: "GHG_assessment_info",
                    data: {
                        template_id: selectedRow.id,
                        organizational_boundaries: escapeSingleQuotes(editableGHGInfo.organizationalBoundaries),
                        scope3_emission: escapeSingleQuotes(editableGHGInfo.operationalBoundariesScope3),
                        co2_biogenic_emission: escapeSingleQuotes(editableGHGInfo.operationalBoundariesCO2),
                        base_year: escapeSingleQuotes(editableGHGInfo.baseYearLine1),
                        clarification: escapeSingleQuotes(editableGHGInfo.baseYearLine2),
                        context: escapeSingleQuotes(editableGHGInfo.baseYearLine3),
                        assumption: escapeSingleQuotes(editableGHGInfo.assumption),
                        exclusion: escapeSingleQuotes(editableGHGInfo.exclusion),
                        methodology: escapeSingleQuotes(editableGHGInfo.formattedEmissions),
                        env_references: escapeSingleQuotes(editableGHGInfo.formattedReferences),
                    },
                    condition: `template_id = '${selectedRow.id}'`,
                });

                const response = await fetch(apiUrl, {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: body,
                });

                const result = await response.json();

                if (result.status === "success") {
                    alert("Information updated successfully!");
                    fetchGHGInfo();
                    loadGHGInfo();
                } else {
                    alert("Failed to save information.");
                }
            } catch (error) {
                console.error("Error updating LCA info:", error);
                alert("Error saving information.");
            }
        } else {
            alert("User ID is null. Cannot update information.");
        }
    };

    return (<div className="lca-container">
        <div className="container-header">
            <h2
                onClick={toggleContainer}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                style={{
                    cursor: 'pointer', color: isHovered ? 'grey' : 'black', // Change 'blue' to your desired hover color
                }}
            >{selectedText.orgTitle || 'GHG Emission Assessment'}</h2>
        </div>
        {isOpen && (<>
            <div className="section general-info-layout">
                <div className="general-info-header">
                    <h2 className="centered-title">{selectedText.generalInformation || 'General Information'}</h2>
                    <button className="right-button" onClick={updateGHGInfo}
                            title="Save lca"><Icon icon="mdi:floppy-disk"
                                                   style={{fontSize: '16px'}}/></button>
                </div>
            </div>
            <div className="section">
                <h2>{selectedText.organizationalBoundaries || 'ORGANIZATIONAL BOUNDARIES'}</h2>
                <div className="subsection">
                    <p>{organizationalBoundariesDescription}</p>
                    <select className="dropdown-selector" value={editableGHGInfo.organizationalBoundaries}
                            onChange={(event) => handleSelectChange(event, 'organizationalBoundaries')}>
                        <option value="" disabled>Select an option</option>
                        <option value="Equity Share">Equity Share</option>
                        <option value="Financial Control">Financial Control</option>
                        <option value="Operational Control">Operational Control</option>
                    </select>
                </div>
            </div>
            <div className="section">
                <h2>{selectedText.operationalBoundaries || 'OPERATIONAL BOUNDARIES'}</h2>
                <div className="subsection">
                    <p>{operationalBoundariesScope3}</p>
                    <select className="dropdown-selector" value={editableGHGInfo.operationalBoundariesScope3}
                            onChange={(event) => handleSelectChange(event, 'operationalBoundariesScope3')}>
                        <option value="" disabled>Select an option</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                    </select>
                </div>
                <div className="subsection">
                    <p>{operationalBoundariesCO2}</p>
                    {textArea('', 'operationalBoundariesCO2', false)}
                </div>
            </div>
            <div className="section">
                <h2>{selectedText.baseYear || 'BASE YEAR'}</h2>
                <div className="subsection">
                    <p>{baseYearLine1}</p>
                    {textArea('', 'baseYearLine1', false)}
                </div>
                <div className="subsection">
                    <p>{baseYearLine2}</p>
                    {textArea('', 'baseYearLine2', false)}
                </div>
                <div className="subsection">
                    <p>{baseYearLine3}</p>
                    {textArea('', 'baseYearLine3', false)}
                </div>
            </div>
            <div className="section">
                    <h2>{selectedText.assumption || 'ASSUMPTION'}</h2>
                {textArea('', 'assumption', false)}
            </div>
            <div className="section">
                <h2>{selectedText.exclusion || 'EXCLUSION'}</h2>
                {textArea('', 'exclusion', false)}
            </div>
            <div className="section">
                <h2>{selectedText.methodology || 'METHODOLOGY'}</h2>
                {textArea('', 'formattedEmissions', true)}
            </div>
            <div className="section">
                <h2>{selectedText.references || 'REFERENCES'}</h2>
                {textArea(formattedStaticReferences, 'formattedReferences', true)}
            </div>
        </>)}
    </div>);
}

export default OrganizationBottomLCA;
