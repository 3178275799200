import axios from "axios";

export const findGaddiRoute = async (requestData) => {
  const organizationInfoString = localStorage.getItem("organizationInfo");
  let organizationId = null;

  if (organizationInfoString) {
    try {
      const organizationInfo = JSON.parse(organizationInfoString);
      organizationId = organizationInfo.organizationId;
    } catch (error) {
      return {
        status: 400,
        message: "Error parsing organizationInfo from local storage",
      };
    }
  }

  if (!organizationId) {
    return {
      status: 400,
      message: "Organization ID is missing from local storage",
    };
  }

  requestData.organizationId = organizationId;

  const options = {
    method: "POST",
    url: `https://gaddi-api-temp.vrtta.green/2B5BDE74CACDE15FDDA5FFC4DACB7F/gaddi`,
    data: requestData,
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const response = await axios(options);
    return {
      data: response.data,
      status: response.status,
    };
  } catch (error) {
    return {
      data: error.data,
      status: error.status,
    };
  }
};

export const findGaddiGoogleRoute = async (requestData) => {
  const options = {
    method: "POST",
    url: `https://gaddi-api-temp.vrtta.green/2B5BDE74CACDE15FDDA5FFC4DACB7F/user`,
    data: requestData,
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const response = await axios(options);
    return {
      data: response.data,
      status: response.status,
    };
  } catch (error) {
    return {
      data: error.data,
      status: error.status,
    };
  }
};
