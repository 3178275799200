import axios from "axios";
import { serverConfig } from "./serverConfig";

// Register a new user (organization) with the server
export const Register = async (requestData) => {
  const options = {
    method: "POST",
    url: `${serverConfig.serverAddress}/auth/register`,
    data: requestData,
    headers: {
      "Content-Type": "application/json",
      "api-key": serverConfig.apiKey,
    },
  };

  try {
    const response = await axios(options);
    // Store JWT token in localStorage on successful registration
    if (response.status === 201 && response.data.access_token) {
      localStorage.setItem("jwtToken", response.data.access_token);
    }
    return {
      status: response.status,
      message: response.data.message || response.statusText,
      data: response.data,
    };
  } catch (error) {
    return {
      status: error.response?.status || 500,
      message: error.response?.data?.message || error.response?.statusText || "Registration failed",
    };
  }
};

// Login a user with the server
export const Login = async (requestData) => {
  const options = {
    method: "POST",
    url: `${serverConfig.serverAddress}/auth/login`,
    data: requestData,
    headers: {
      "Content-Type": "application/json",
      "api-key": serverConfig.apiKey,
    },
  };

  try {
    const response = await axios(options);

    return {
      status: response.status,
      message: response.data.message || response.statusText,
      data: response.data,
    };
  } catch (error) {
    return {
      status: error.response?.status || 500,
      message: error.response?.data?.message || error.response?.statusText || "Login failed",
    };
  }
};

// Verify registration code
export const VerifyRegistrationCode = async (requestData) => {
  const options = {
    method: "POST",
    url: `${serverConfig.serverAddress}/auth/verify/registration`,
    data: requestData,
    headers: {
      "Content-Type": "application/json",
      "api-key": serverConfig.apiKey,
    },
  };

  try {
    const response = await axios(options);
    // Store JWT token in localStorage on successful login
    if (response.status === 201 && response.data.access_token) {
      localStorage.setItem("jwtToken", response.data.access_token);
    }
    return {
      status: response.status,
      message: response.data.message || response.statusText,
      data: response.data,
    };
  } catch (error) {
    return {
      status: error.response?.status || 500,
      message: error.response?.data?.message || error.response?.statusText || "Verification failed",
    };
  }
};

// Verify login code
export const VerifyLoginCode = async (requestData) => {
  const options = {
    method: "POST",
    url: `${serverConfig.serverAddress}/auth/verify/login`,
    data: requestData,
    headers: {
      "Content-Type": "application/json",
      "api-key": serverConfig.apiKey,
    },
  };

  try {
    const response = await axios(options);
    // Store JWT token in localStorage on successful login
    if (response.status === 200 && response.data.access_token) {
      localStorage.setItem("jwtToken", response.data.access_token);
    }
    return {
      status: response.status,
      message: response.data.message || response.statusText,
      data: response.data,
    };
  } catch (error) {
    return {
      status: error.response?.status || 500,
      message: error.response?.data?.message || error.response?.statusText || "Verification failed",
    };
  }
};

// Resend verification code
export const ResendVerificationCode = async (requestData) => {
  const options = {
    method: "POST",
    url: `${serverConfig.serverAddress}/auth/resend-code`,
    data: requestData,
    headers: {
      "Content-Type": "application/json",
      "api-key": serverConfig.apiKey,
    },
  };

  try {
    const response = await axios(options);
    return {
      status: response.status,
      message: response.data.message || response.statusText,
      data: response.data,
    };
  } catch (error) {
    return {
      status: error.response?.status || 500,
      message: error.response?.data?.message || error.response?.statusText || "Failed to resend verification code",
    };
  }
};

// Logout the current user by clearing stored JWT token
export const Logout = () => {
  localStorage.removeItem("jwtToken");
};

// Fetch current organization details from the server
export const AuthMeRequest = async () => {
  const token = localStorage.getItem("jwtToken");
  if (!token) {
    return {
      status: 401,
      message: "Unauthorized",
      organizationInfo: false,
    };
  }

  const options = {
    method: "GET",
    url: `${serverConfig.serverAddress}/auth/me`,
    headers: {
      "Content-Type": "application/json",
      "api-key": serverConfig.apiKey,
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const response = await axios(options);

    if (response.status === 200) {
      localStorage.setItem("organizationInfo", JSON.stringify(response.data.organizationInfo));
    }
    return {
      status: response.status,
      message: response.data.message || response.statusText,
      organizationInfo: response.data.organizationInfo,
    };
  } catch (error) {
    return {
      status: error.response?.status || 500,
      message: error.response?.data?.message || error.response?.statusText || "Failed to fetch organization details",
      organizationInfo: false,
    };
  }
};
