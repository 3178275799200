import styles from "./FetchRoutes.module.css";
import { fetchDispatchedRoutes } from "../helpers/routeHelper";
import { GrCycle } from "react-icons/gr";

const RouteFetcher = ({ onUpload }) => {
  const fetchDispatched = async () => {
    try {
      const response = await fetchDispatchedRoutes();
      if (response.status === 200) {
        onUpload();
      } else {
        console.log(response.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <label className={styles.button}>
        <GrCycle size={"20px"} onClick={fetchDispatched} />
      </label>
    </div>
  );
};

export default RouteFetcher;
