import React, { useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom"; // Import useLocation
import "../style/components-style/Header.css";
import logo from "../assets/logo/ArivuHeader.png";
import { useAuth } from "../authentication/AuthProvider";
import SideMenu from "./SideMenu"; // Import the SideMenu component
import { translations } from "../app_components/Translation"; // Import translations

function Header({ selectedLanguage }) {
  const [isMenuOpen, setIsMenuOpen] = useState(false); // State to manage side menu visibility
  const location = useLocation();
  const selectedText = translations[selectedLanguage]?.header || {};

  // Determine which set of tabs to show based on the current path
  const isFlotsPath = ["/homeorganizational", "/organizationalsupplychain", "/organizationalorganization", "/organizationalcorporate", "/organizationalcomparison", "/netzero", "/fleet/performance", "/cost/routes", "/settings"].includes(location.pathname);

  const tabs = isFlotsPath
    ? [
        { label: selectedText.supply_chain || "Value Chain", path: "/organizationalsupplychain" },
        { label: selectedText.organization || "Organization", path: "/organizationalorganization" },
        { label: selectedText.corporate || "Corporate", path: "/organizationalcorporate" },
        { label: selectedText.comparison || "Comparison", path: "/organizationalcomparison" },
        { label: selectedText.netZeroTitle || "Net Zero", path: "/netzero" },
        { label: selectedText.fleetTitle || "Fleet Performance", path: "/fleet/performance" },
        { label: selectedText.costOptmizer || "Cost Optimizer", path: "/cost/routes" },
      ]
    : [
        { label: selectedText.supplier || "Supplier", path: "/supplier" },
        { label: selectedText.utilizer || "Utilizer", path: "/utilizer" },
        { label: selectedText.comparison || "Comparison", path: "/comparison" },
        { label: selectedText.reuse || "Reuse", path: "/reuse" },
        { label: selectedText.circularity || 'Circularity', path: '/circularity' },
      ];

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div>
      <div className="header">
        <div className="header-left">
          <button className="menu-icon" onClick={toggleMenu}>
            ☰
          </button>
          <Link to="/homeviewtype" className="logo-link">
            <img src={logo} alt="Arivu" style={{ height: "20px" }} />
          </Link>
        </div>
        <div className="header-right">
          {location.pathname !== "/homeviewtype" &&
            tabs.map((tab) => (
              <Link key={tab.label} to={tab.path} className={`header-tab ${location.pathname === tab.path ? "active-tab" : ""}`}>
                {tab.label}
              </Link>
            ))}
        </div>
      </div>
      <SideMenu isOpen={isMenuOpen} onClose={toggleMenu} selectedLanguage={selectedLanguage} />
    </div>
  );
}

export default Header;
