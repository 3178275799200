import React, { useState, useEffect } from "react";
import { createVehicle, editVehicle, deleteVehicle, getAllVehicles } from "../helpers/vehicleHelper";
import ConfirmModal from "../shared/ConfirmModal";
import { FaPlus, FaEdit, FaTrash } from "react-icons/fa";
import styles from "./Fleet.module.css";

const Fleet = () => {
  const [vehicles, setVehicles] = useState([]);
  const [editing, setEditing] = useState(null);
  const [newVehicle, setNewVehicle] = useState({ name: "", licensePlate: "", make: "" });
  const [editData, setEditData] = useState({});
  const [showForm, setShowForm] = useState(false);
  const [message, setMessage] = useState("");

  // Fetch vehicles on component mount
  useEffect(() => {
    const fetchVehicles = async () => {
      const response = await getAllVehicles();
      if (response.status === 200) {
        setVehicles(response.data.vehicles);
      } else {
        alert("Failed to fetch vehicles");
      }
    };
    fetchVehicles();
  }, []);

  // Handle new vehicle creation
  const handleCreate = async () => {
    const response = await createVehicle(newVehicle);
    if (response.status === 200) {
      setMessage("Vehicle created successfully!");
      setNewVehicle({ name: "", licensePlate: "", make: "" });
      setShowForm(false); // Hide form after creation
      fetchVehicles();
    } else {
      alert("Failed to create vehicle");
    }
  };

  // Handle editing a vehicle
  const handleEdit = (vehicle) => {
    setEditing(vehicle.vehicleId);
    setEditData({ ...vehicle });
  };

  // Save edited vehicle
  const handleSave = async () => {
    const response = await editVehicle(editing, editData);
    if (response.status === 200) {
      setMessage("Vehicle updated successfully!");
      setVehicles(vehicles.map((vehicle) => (vehicle.vehicleId === editing ? response.data : vehicle)));
      setEditing(null);
      fetchVehicles();
    } else {
      alert("Failed to edit vehicle");
    }
  };

  // Handle deleting a vehicle
  const handleDelete = async (vehicleId) => {
    const response = await deleteVehicle(vehicleId);
    if (response.status === 200) {
      setMessage("Vehicle deleted successfully!");
      fetchVehicles();
    } else {
      alert("Failed to delete vehicle");
    }
  };

  // Refetch vehicles
  const fetchVehicles = async () => {
    const response = await getAllVehicles();
    if (response.status === 200) {
      setVehicles(response.data.vehicles);
    } else {
      alert("Failed to fetch vehicles");
    }
  };

  const deleteIcon = () => <FaTrash color="#d15533" size="20px" />;

  return (
    <div className={styles.fleetContainer}>
      <div className={styles.title}>
        Fleet Management
        <button className={styles.addButton} onClick={() => setShowForm(!showForm)}>
          <FaPlus style={{ marginRight: "5px" }} /> Add New Vehicle
        </button>
      </div>
      {showForm && (
        <div className={styles.section}>
          <div className={styles.field}>
            <label className={styles.label}>Vehicle Name</label>
            <input className={styles.input} type="text" value={newVehicle.name} onChange={(e) => setNewVehicle({ ...newVehicle, name: e.target.value })} />
          </div>
          <div className={styles.field}>
            <label className={styles.label}>License Plate</label>
            <input className={styles.input} type="text" value={newVehicle.licensePlate} onChange={(e) => setNewVehicle({ ...newVehicle, licensePlate: e.target.value })} />
          </div>
          <div className={styles.field}>
            <label className={styles.label}>Make</label>
            <input className={styles.input} type="text" value={newVehicle.make} onChange={(e) => setNewVehicle({ ...newVehicle, make: e.target.value })} />
          </div>
          <div className={styles.actions}>
            <button className={styles.button} onClick={handleCreate}>
              Create Vehicle
            </button>
          </div>
        </div>
      )}
      {/* Vehicles List */}
      <div className={styles.divider}></div>
      <div className={styles.vehicleDetailsHeader}>
        <div>Vehicle Name</div>
        <div>License Plate</div>
        <div>Make</div>
      </div>{" "}
      <div className={styles.detailsContainer}>
        {vehicles &&
          vehicles.map((vehicle) => (
            <div key={vehicle.vehicleId} className={styles.vehicleItem}>
              {editing === vehicle.vehicleId ? (
                <>
                  <div className={styles.field}>
                    <label className={styles.label}>Vehicle Name</label>
                    <input className={styles.input} type="text" value={editData.name} onChange={(e) => setEditData({ ...editData, name: e.target.value })} />
                  </div>
                  <div className={styles.field}>
                    <label className={styles.label}>License Plate</label>
                    <input className={styles.input} type="text" value={editData.licensePlate} onChange={(e) => setEditData({ ...editData, licensePlate: e.target.value })} />
                  </div>
                  <div className={styles.field}>
                    <label className={styles.label}>Make</label>
                    <input className={styles.input} type="text" value={editData.make} onChange={(e) => setEditData({ ...editData, make: e.target.value })} />
                  </div>
                  <div className={styles.actions}>
                    <button className={styles.button} onClick={handleSave}>
                      Save
                    </button>
                    <button className={styles.button} onClick={() => setEditing(null)}>
                      Cancel
                    </button>
                  </div>
                </>
              ) : (
                <>
                  {/* Vehicle Details */}
                  <div className={styles.vehicleDetails}>
                    <div>{vehicle.name}</div>
                    <div>{vehicle.licensePlate}</div>
                    <div>{vehicle.make}</div>
                  </div>
                  <div className={styles.actions}>
                    <button className={styles.iconButton}>
                      <FaEdit color="#d15533" size="20px" onClick={() => handleEdit(vehicle)} />
                    </button>
                    <ConfirmModal title="Delete" text="Are you sure you want to delete this vehicle?" onConfirm={() => handleDelete(vehicle.vehicleId)} iconButton={deleteIcon} />
                  </div>
                </>
              )}
            </div>
          ))}
      </div>{" "}
    </div>
  );
};

export default Fleet;
