import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AuthMeRequest, Login, VerifyLoginCode } from "../helpers/authHelper";

const withAuth = (WrappedComponent) => {
  const ComponentWithAuth = (props) => {
    const navigate = useNavigate();

    useEffect(() => {
      const checkAuth = async () => {
        try {
          const response = await AuthMeRequest();
          if (response.status !== 200 || !response.organizationInfo) {
            localStorage.setItem("jwtToken", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmcmVzaCI6ZmFsc2UsImlhdCI6MTczODQyNzI4MCwianRpIjoiMDEzYTdkNjItOTU1OS00MmFlLTkzZDItNGUwOTYyNTQ2MmY2IiwidHlwZSI6ImFjY2VzcyIsInN1YiI6Ijc1YzZlNTQ2LTE1ZDUtNDdkNC04YzdhLTVkYmVhNTE3OWNhOCIsIm5iZiI6MTczODQyNzI4MCwiY3NyZiI6IjdiYjdlZmI3LWRlMzAtNDU3Yi1iYzg3LWFmMDFhZDI5NTFlZSJ9.I-IyE6UwZTZLJH6ZWR-aKADsbYW5c7AsMgZAQTIJiT0");
          }
        } catch (error) {
          navigate("/login");
        }
      };

      checkAuth();
    }, [navigate]);

    return <WrappedComponent {...props} />;
  };

  ComponentWithAuth.displayName = `WithAuth(${getDisplayName(WrappedComponent)})`;
  return ComponentWithAuth;
};

const getDisplayName = (WrappedComponent) => {
  return WrappedComponent.displayName || WrappedComponent.name || "Component";
};

export default withAuth;
