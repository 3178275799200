import axios from "axios";
import { getOrgId } from "../../utils/costUtils/getOrganizationId";

// Upload file to server
export const uploadFileToServer = async (file) => {
  const uploadUrl = "https://gaddi-api-temp.vrtta.green/2B5BDE74CACDE15FDDA5FFC4DACB7F/upload/prices";
  const organizationId = getOrgId();

  if (typeof organizationId === "object") {
    return organizationId;
  }

  const formData = new FormData();
  formData.append("data", JSON.stringify({ organizationId }));
  if (file instanceof File) {
    formData.append("file", file);
  } else {
    return {
      status: 400,
      message: "Invalid file object",
    };
  }

  const options = {
    method: "POST",
    url: uploadUrl,
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: formData,
  };

  try {
    const response = await axios(options);
    return {
      status: response.status,
      message: response.data.message || response.statusText,
      data: response.data,
    };
  } catch (error) {
    return {
      status: error.response?.status || 500,
      message: error.response?.data?.message || error.response?.statusText || "Failed to upload file",
    };
  }
};

// Retrieve price dates
export const retrievePriceDates = async () => {
  const datesUrl = "https://gaddi-api-temp.vrtta.green/2B5BDE74CACDE15FDDA5FFC4DACB7F/dates";
  const organizationId = getOrgId();

  if (typeof organizationId === "object") {
    return organizationId;
  }

  const options = {
    method: "POST",
    url: datesUrl,
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify({ organizationId }),
  };

  try {
    const response = await axios(options);
    return {
      status: response.status,
      message: response.data.message || response.statusText,
      data: response.data,
    };
  } catch (error) {
    return {
      status: error.response?.status || 500,
      message: error.response?.data?.message || error.response?.statusText || "Failed to retrieve price dates",
    };
  }
};

// Retrieve price sheet details
export const retrievePriceSheetDetails = async (date) => {
  const priceSheetUrl = "https://gaddi-api-temp.vrtta.green/2B5BDE74CACDE15FDDA5FFC4DACB7F/price_sheet";
  const organizationId = getOrgId();

  if (typeof organizationId === "object") {
    return organizationId;
  }

  if (!date) {
    return {
      status: 400,
      message: "Date parameter is required",
    };
  }

  const options = {
    method: "POST",
    url: priceSheetUrl,
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify({ organizationId, date }),
  };

  try {
    const response = await axios(options);
    return {
      status: response.status,
      message: response.data.message || response.statusText,
      data: response.data,
    };
  } catch (error) {
    return {
      status: error.response?.status || 500,
      message: error.response?.data?.message || error.response?.statusText || "Failed to retrieve price sheet details",
    };
  }
};

// Upload IFTA file to server
export const uploadIFTAFileToServer = async (file) => {
  const uploadUrl = "https://gaddi-api-temp.vrtta.green/2B5BDE74CACDE15FDDA5FFC4DACB7F/upload/ifta";

  if (file instanceof File) {
    const formData = new FormData();
    formData.append("file", file);

    const options = {
      method: "POST",
      url: uploadUrl,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    };

    try {
      const response = await axios(options);
      return {
        status: response.status,
        message: response.data.message || response.statusText,
        data: response.data,
      };
    } catch (error) {
      return {
        status: error.response?.status || 500,
        message: error.response?.data?.message || error.response?.statusText || "Failed to upload IFTA file",
      };
    }
  } else {
    return {
      status: 400,
      message: "Invalid file object",
    };
  }
};
