import axios from "axios";
import { serverConfig } from "./serverConfig";

// Edit organization information
export const editOrganizationInfo = async (requestData) => {
  const token = localStorage.getItem("jwtToken");

  const options = {
    method: "POST",
    url: `${serverConfig.serverAddress}/organization/editInfo`,
    data: requestData,
    headers: {
      "Content-Type": "application/json",
      "api-key": serverConfig.apiKey,
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const response = await axios(options);
    return {
      status: response.status,
      message: response.data.message || response.statusText,
      data: response.data,
    };
  } catch (error) {
    return {
      status: error.response?.status || 500,
      message: error.response?.data?.message || error.response?.statusText || "Failed to update organization information",
    };
  }
};

// Change organization password
export const changeOrganizationPassword = async (requestData) => {
  const token = localStorage.getItem("jwtToken");

  const options = {
    method: "POST",
    url: `${serverConfig.serverAddress}/organization/changePassword`,
    data: requestData,
    headers: {
      "Content-Type": "application/json",
      "api-key": serverConfig.apiKey,
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const response = await axios(options);
    return {
      status: response.status,
      message: response.data.message || response.statusText,
      data: response.data,
    };
  } catch (error) {
    return {
      status: error.response?.status || 500,
      message: error.response?.data?.message || error.response?.statusText || "Failed to change password",
    };
  }
};

// Change organization email
export const changeOrganizationEmail = async (requestData) => {
  const token = localStorage.getItem("jwtToken");

  const options = {
    method: "POST",
    url: `${serverConfig.serverAddress}/organization/changeEmail`,
    data: requestData,
    headers: {
      "Content-Type": "application/json",
      "api-key": serverConfig.apiKey,
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const response = await axios(options);
    return {
      status: response.status,
      message: response.data.message || response.statusText,
      data: response.data,
    };
  } catch (error) {
    return {
      status: error.response?.status || 500,
      message: error.response?.data?.message || error.response?.statusText || "Failed to change email",
    };
  }
};

// Change organization API keys
export const changeOrganizationApiKeys = async (requestData) => {
  const token = localStorage.getItem("jwtToken");

  const options = {
    method: "POST",
    url: `${serverConfig.serverAddress}/organization/changeApiKeys`,
    data: requestData,
    headers: {
      "Content-Type": "application/json",
      "api-key": serverConfig.apiKey,
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const response = await axios(options);
    return {
      status: response.status,
      message: response.data.message || response.statusText,
      data: response.data,
    };
  } catch (error) {
    return {
      status: error.response?.status || 500,
      message: error.response?.data?.message || error.response?.statusText || "Failed to change API keys",
    };
  }
};
