import "../style/components-style/NetZeroBottomTool.css"
import {Line} from "react-chartjs-2";
import React from "react";

const targetSettingMethods = [
    "Absolute Contraction Approach",
    "Economic intensity",
    "Physical intensity",
    "Cement SDA 1.5ºC"
]

function Scope3ShortTermTool({
                                 resetForm,
                                 saveShortTermToolInputs,
                                 targetSettingMethod,
                                 setTargetSettingMethod,
                                 baseYear,
                                 setBaseYear,
                                 baseYearList,
                                 targetYear,
                                 setTargetYear,
                                 targetYearList,
                                 scope3Emission,
                                 yearsList,
                             }) {
    return (
        <>
            <div className="section">
                <div className="freight-tool-button-container">
                    <button className="freight-tool-reset-button" onClick={resetForm}>Reset</button>
                    <button className="freight-tool-save-button" onClick={saveShortTermToolInputs}>Save</button>
                </div>
                <h3>Input data</h3>
                <div className="data-table">
                    <div className="table-row">
                        <div className="row label">Target setting method</div>
                        <select
                            className="input grey"
                            value={targetSettingMethod}
                            onChange={(e) => setTargetSettingMethod(e.target.value)}
                        >
                            <option value="">Select a transport category</option>
                            {targetSettingMethods.map((year, index) => (
                                <option key={index} value={year}>{year}</option>
                            ))}
                        </select>
                    </div>
                    <div className="table-row">
                        <div className="row label">Base year</div>
                        <select
                            className="input grey"
                            value={baseYear}
                            onChange={(e) => setBaseYear(e.target.value)}
                        >
                            <option value="">Select a Year</option>
                            {baseYearList.map((year, index) => (
                                <option key={index} value={year}>{year}</option>
                            ))}
                        </select>
                    </div>
                    <div className="table-row">
                        <div className="row label">Base Year | Scope 3 emissions (tCO₂e)</div>
                        <div className="input grey">{scope3Emission.toFixed(2)}</div>
                    </div>
                    <div className="table-row">
                        <div className="row label">Target year</div>
                        <select
                            className="input grey"
                            value={targetYear}
                            onChange={(e) => setTargetYear(e.target.value)}
                        >
                            <option value="">Select a Year</option>
                            {targetYearList.map((year, index) => (
                                <option key={index} value={year}>{year}</option>
                            ))}
                        </select>
                    </div>
                </div>
            </div>

            <div className="section">
                <h3>Cross-sector absolute reduction / Absolute contraction approach (ACA)</h3>
                <div className="data-table">
                    <div className="table-row table-header">
                        <div className="header-column"></div>
                        <div className="header-column">Base year ({baseYear})</div>
                        <div className="header-column">Target year ({targetYear})</div>
                        <div className="header-column">% SBT Reduction</div>
                    </div>

                    <div className="table-row">
                        <div className="row label">Absolute emissions - WB2C (tCO2e)</div>
                        <div className="input grey">{scope3Emission.toFixed(2)}</div>
                        <div className="input grey">{(scope3Emission * (1 - 0.025 * (yearsList.length - 1))).toFixed(2)}</div>
                        <div className="input grey">{(((yearsList.length - 1) * 0.025) * 100).toFixed(2)}%</div>
                    </div>

                    <div className="table-row">
                        <div className="row label">Absolute emissions - 1.5C (tCO2e)</div>
                        <div className="input grey">{scope3Emission.toFixed(2)}</div>
                        <div className="input grey">{(scope3Emission * (1 - (0.042 * yearsList.length - 1))).toFixed(2)}</div>
                        <div className="input grey">{(((yearsList.length - 1) * 0.042) * 100).toFixed(2)}%</div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Scope3ShortTermTool;