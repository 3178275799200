import React, { useEffect, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import styles from "./Invoices.module.css";

const API_URL = "http://bvdapiservices.bvdpetroleum.com/api/getAllTransactions.php";
const API_KEY = "xw9KfMRFYWUKmr0C5a0T6nRQzdiu2gqe";

const Invoices = () => {
  const [transactions, setTransactions] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState("2024-11-01");
  const [endDate, setEndDate] = useState("2024-12-31");
  const limit = 1000;

  const fetchTransactions = async (pageNum) => {
    setLoading(true);
    try {
      const url = `${API_URL}?starttime=${startDate}&endtime=${endDate}&limit=${limit}&page=${pageNum}`;
      const response = await fetch(url, {
        headers: {
          Authorization: API_KEY,
        },
      });
      const data = await response.json();
      if (Array.isArray(data)) {
        setTransactions(data);
      }
    } catch (error) {
      console.error("Error fetching transactions:", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchTransactions(page);
  }, [page, startDate, endDate]);

  const columnDefs = [
    { headerName: "Transaction ID", field: "transactionId", sortable: true },
    { headerName: "Date", field: "date_time", sortable: true },
    { headerName: "Card Number", field: "cardNumber" },
    { headerName: "Company", field: "company Name" },
    { headerName: "Driver", field: "driver_name" },
    { headerName: "Truck Number", field: "truck_number" },
    { headerName: "Total Amount", field: "totalAmount", sortable: true },
    { headerName: "Invoice #", field: "Invoice#" },
    { headerName: "Site Name", field: "Site Name" },
    { headerName: "Location", field: "location.city" },
  ];

  return (
    <div className={styles.container}>
      <div className={styles.filters}>
        <label>Start Date:</label>
        <input type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} />

        <label>End Date:</label>
        <input type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} />

        <button onClick={() => fetchTransactions(1)}>Fetch Data</button>
      </div>

      {loading ? (
        <p>Loading transactions...</p>
      ) : (
        <div className="ag-theme-alpine" style={{ height: 500, width: "100%" }}>
          <AgGridReact rowData={transactions} columnDefs={columnDefs} pagination={true} paginationPageSize={20} />
        </div>
      )}

      <div className={styles.pagination}>
        <button onClick={() => setPage((prev) => Math.max(prev - 1, 1))} disabled={page === 1}>
          Previous
        </button>
        <span>Page {page}</span>
        <button onClick={() => setPage((prev) => prev + 1)}>Next</button>
      </div>
    </div>
  );
};

export default Invoices;
