import axios from "axios";
import { serverConfig } from "./serverConfig";

// Fetch dispatched routes
export const fetchDispatchedRoutes = async () => {
  const token = localStorage.getItem("jwtToken");

  const options = {
    method: "POST",
    url: `${serverConfig.serverAddress}/route/fetchDispatched`,
    headers: {
      "Content-Type": "application/json",
      "api-key": serverConfig.apiKey,
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const response = await axios(options);
    return {
      status: response.status,
      message: response.data.message || response.statusText,
      data: response.data,
    };
  } catch (error) {
    return {
      status: error.response?.status || 500,
      message: error.response?.data?.message || error.response?.statusText || "Failed to fetch dispatched routes",
    };
  }
};

// Create a new route
export const createRoute = async (routeData) => {
  const token = localStorage.getItem("jwtToken");

  const options = {
    method: "POST",
    url: `${serverConfig.serverAddress}/route/create`,
    data: routeData,
    headers: {
      "Content-Type": "application/json",
      "api-key": serverConfig.apiKey,
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const response = await axios(options);
    return {
      status: response.status,
      message: response.data.message || response.statusText,
      data: response.data,
    };
  } catch (error) {
    return {
      status: error.response?.status || 500,
      message: error.response?.data?.message || error.response?.statusText || "Failed to create route",
    };
  }
};

// Get route by ID
export const getRouteById = async (routeId) => {
  const token = localStorage.getItem("jwtToken");

  const options = {
    method: "GET",
    url: `${serverConfig.serverAddress}/route/${routeId}`,
    headers: {
      "Content-Type": "application/json",
      "api-key": serverConfig.apiKey,
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const response = await axios(options);
    return {
      status: response.status,
      message: response.data.message || response.statusText,
      data: response.data,
    };
  } catch (error) {
    return {
      status: error.response?.status || 500,
      message: error.response?.data?.message || error.response?.statusText || "Failed to fetch route by ID",
    };
  }
};

// Update route by ID
export const updateRoute = async (routeId, routeData) => {
  const token = localStorage.getItem("jwtToken");

  const options = {
    method: "PUT",
    url: `${serverConfig.serverAddress}/route/${routeId}`,
    data: routeData,
    headers: {
      "Content-Type": "application/json",
      "api-key": serverConfig.apiKey,
      Authorization: `Bearer ${token}`,
    },
  };

  console.log("updateRoute", options);

  try {
    const response = await axios(options);

    return {
      status: response.status,
      message: response.data.message || response.statusText,
      data: response.data,
    };
  } catch (error) {
    return {
      status: error.response?.status || 500,
      message: error.response?.data?.message || error.response?.statusText || "Failed to update route",
    };
  }
};

// Delete route by ID
export const deleteRoute = async (routeId) => {
  const token = localStorage.getItem("jwtToken");

  const options = {
    method: "DELETE",
    url: `${serverConfig.serverAddress}/route/${routeId}`,
    headers: {
      "Content-Type": "application/json",
      "api-key": serverConfig.apiKey,
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const response = await axios(options);
    return {
      status: response.status,
      message: response.data.message || response.statusText,
      data: response.data,
    };
  } catch (error) {
    return {
      status: error.response?.status || 500,
      message: error.response?.data?.message || error.response?.statusText || "Failed to delete route",
    };
  }
};

// Get all routes with pagination
export const getAllRoutes = async (page = 1, limit = 10) => {
  const token = localStorage.getItem("jwtToken");

  const options = {
    method: "GET",
    url: `${serverConfig.serverAddress}/routes`,
    params: { page, limit },
    headers: {
      "Content-Type": "application/json",
      "api-key": serverConfig.apiKey,
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const response = await axios(options);
    return {
      status: response.status,
      message: response.data.message || response.statusText,
      data: response.data,
    };
  } catch (error) {
    return {
      status: error.response?.status || 500,
      message: error.response?.data?.message || error.response?.statusText || "Failed to fetch routes",
    };
  }
};

// Get routes by status with pagination
export const getRoutesByStatus = async (status, page, limit) => {
  const token = localStorage.getItem("jwtToken");

  const options = {
    method: "GET",
    url: `${serverConfig.serverAddress}/routes/status`,
    params: { status, page, limit },
    headers: {
      "Content-Type": "application/json",
      "api-key": serverConfig.apiKey,
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const response = await axios(options);
    console.log("getRoutesByStatus", response.data);
    return {
      status: response.status,
      message: response.data.message || response.statusText,
      data: response.data,
    };
  } catch (error) {
    return {
      status: error.response?.status || 500,
      message: error.response?.data?.message || error.response?.statusText || "Failed to fetch routes by status",
    };
  }
};

// Get dispatched routes count
export const getDispatchedRoutesCount = async () => {
  const token = localStorage.getItem("jwtToken");

  const options = {
    method: "GET",
    url: `${serverConfig.serverAddress}/routes/dispatchedCount`,
    headers: {
      "Content-Type": "application/json",
      "api-key": serverConfig.apiKey,
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const response = await axios(options);
    return {
      status: response.status,
      message: response.data.message || response.statusText,
      data: response.data,
    };
  } catch (error) {
    return {
      status: error.response?.status || 500,
      message: error.response?.data?.message || error.response?.statusText || "Failed to fetch dispatched routes count",
    };
  }
};

// Get assigned routes count
export const getAssignedRoutesCount = async () => {
  const token = localStorage.getItem("jwtToken");

  const options = {
    method: "GET",
    url: `${serverConfig.serverAddress}/routes/assignedCount`,
    headers: {
      "Content-Type": "application/json",
      "api-key": serverConfig.apiKey,
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const response = await axios(options);
    return {
      status: response.status,
      message: response.data.message || response.statusText,
      data: response.data,
    };
  } catch (error) {
    return {
      status: error.response?.status || 500,
      message: error.response?.data?.message || error.response?.statusText || "Failed to fetch assigned routes count",
    };
  }
};

// Assign route
export const assignRoute = async (routeId, assignData) => {
  const token = localStorage.getItem("jwtToken");

  const options = {
    method: "PUT",
    url: `${serverConfig.serverAddress}/route/${routeId}/assign`,
    data: assignData,
    headers: {
      "Content-Type": "application/json",
      "api-key": serverConfig.apiKey,
      Authorization: `Bearer ${token}`,
    },
  };

  console.log("assignRoute", options);

  try {
    const response = await axios(options);
    return {
      status: response.status,
      message: response.data.message || response.statusText,
      data: response.data,
    };
  } catch (error) {
    return {
      status: error.response?.status || 500,
      message: error.response?.data?.message || error.response?.statusText || "Failed to assign route",
    };
  }
};
