import React, { useState, useRef } from "react";
import { IoAddOutline } from "react-icons/io5";
import { PiFileCsvDuotone } from "react-icons/pi";
import { IoCloseOutline } from "react-icons/io5";
import Modal from "react-modal";
import Papa from "papaparse";
import styles from "./AddRoutes.module.css";

Modal.setAppElement("#root");

const ALL_HEADERS = [
  { headerText: "Source", label: "startAddress" },
  { headerText: "Destination", label: "endAddress" },
  { headerText: "Dispatch Date", label: "dispatchDate" },
  { headerText: "Delivery Date", label: "deliveryDate" },
  { headerText: "Vehicle No.", label: "vehicleNo" },
  { headerText: "Google Link", label: "googleLink" },
  { headerText: "Driver Name", label: "driverName" },
  { headerText: "Driver Mobile", label: "driverMobile" },
  { headerText: "Invoice No.", label: "invoiceNo" },
  { headerText: "Weight", label: "loadWeight" },
  { headerText: "Min Reserve", label: "minReserve" },
  { headerText: "End Reserve", label: "endReserve" },
  { headerText: "Stops", label: "maxStops" },
  { headerText: "Fuel Eco", label: "fuelEconomy" },
  { headerText: "Current Gas", label: "currentGas" },
  { headerText: "Tank Capacity", label: "tankCapacity" },
];

const sanitizeData = (item) => {
  item.fuelEconomy = parseFloat(item.fuelEconomy) || 0.0;
  item.tankCapacity = parseFloat(item.tankCapacity) || 0;
  item.currentGas = parseFloat(item.currentGas) || 0;
  item.minReserve = parseFloat(item.minReserve) || 0;
  item.endReserve = parseFloat(item.endReserve) || 0;
  item.maxStops = parseFloat(item.maxStops) || 0;

  // Ensure status defaults to "trial"
  item.status = item.status || "trial";
  return item;
};

const AddRoutes = ({ onAddTrialRoutes }) => {
  const [isTrialModalOpen, setIsTrialModalOpen] = useState(false);
  const [trialParsedData, setTrialParsedData] = useState([]);
  const trialFileInputRef = useRef(null);

  const handleOpenModal = () => setIsTrialModalOpen(true);

  const handleCloseModal = () => {
    setTrialParsedData([]);
    setIsTrialModalOpen(false);
  };

  const handleAddRow = () => {
    const newRow = ALL_HEADERS.reduce(
      (acc, { label }) => {
        acc[label] = "N/A";
        return acc;
      },
      { status: "trial" },
    );
    setTrialParsedData((prevData) => [...prevData, newRow]);
  };

  const handleEditCell = (rowIndex, label, newValue) => {
    setTrialParsedData((prevData) => {
      const updatedData = [...prevData];
      updatedData[rowIndex][label] = ["minReserve", "endReserve", "maxStops", "fuelEconomy", "currentGas", "tankCapacity", "loadWeight"].includes(label) ? (newValue === "" || isNaN(newValue) ? "" : parseFloat(newValue)) : newValue;
      return updatedData;
    });
  };

  const handleDeleteRow = (rowIndex) => {
    setTrialParsedData((prevData) => prevData.filter((_, index) => index !== rowIndex));
  };

  const handleTrialFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: (result) => {
          const data = result.data.map((item) => sanitizeData({ ...item }));
          setTrialParsedData((prevData) => [...prevData, ...data]);
        },
      });
    }
  };

  const handleUploadTrialRoutes = () => {
    onAddTrialRoutes(trialParsedData);
    handleCloseModal();
  };

  return (
    <>
      <button className={styles.addButton} onClick={handleOpenModal}>
        <IoAddOutline size="25px" />
      </button>

      <Modal isOpen={isTrialModalOpen} onRequestClose={handleCloseModal} className={styles.modal} overlayClassName={styles.overlay}>
        <h2>Trial Routes</h2>
        <div className={styles.controls}>
          <button className={styles.addRowButton} onClick={handleAddRow}>
            <IoAddOutline size="25px" /> Add Trial Route
          </button>
          <input type="file" accept=".csv" ref={trialFileInputRef} onChange={handleTrialFileChange} style={{ display: "none" }} />
          <button className={styles.uploadButton} onClick={() => trialFileInputRef.current.click()}>
            <PiFileCsvDuotone size="25px" /> Import CSV
          </button>
        </div>
        <div className={styles.previewTable}>
          <table>
            {trialParsedData.length > 0 && (
              <thead>
                <tr>
                  <th></th>
                  {ALL_HEADERS.map(({ headerText, label }) => (
                    <th key={label}>{headerText}</th>
                  ))}
                </tr>
              </thead>
            )}
            <tbody>
              {trialParsedData.length > 0 ? (
                trialParsedData.map((row, rowIndex) => (
                  <tr key={rowIndex}>
                    <td>
                      <IoCloseOutline size="30px" color="grey" className={styles.deleteButton} onClick={() => handleDeleteRow(rowIndex)} />
                    </td>
                    {ALL_HEADERS.map(({ label }) => (
                      <td key={label}>
                        <input type={["minReserve", "endReserve", "maxStops", "fuelEconomy", "currentGas", "tankCapacity", "loadWeight"].includes(label) ? "number" : "text"} step="0.01" value={row[label]} onChange={(e) => handleEditCell(rowIndex, label, e.target.value)} className={styles.editableCell} />
                      </td>
                    ))}
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={ALL_HEADERS.length + 1}>No trial routes added yet.</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div className={styles.modalActions}>
          <button className={styles.confirmButton} onClick={handleUploadTrialRoutes}>
            Confirm
          </button>
          <button className={styles.cancelButton} onClick={handleCloseModal}>
            Cancel
          </button>
        </div>
      </Modal>
    </>
  );
};

export default AddRoutes;
