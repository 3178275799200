import { useState, useEffect } from "react";
import withAuth from "../shared/withAuth";
import styles from "./ViewRoutes.module.css";
import RoutesMatrix from "./routes/RoutesMatrix";
import { retrievePriceDates } from "../helpers/priceFileHelper";
import GraphComponent from "./routes/GraphComponent";
import { FaChartLine } from "react-icons/fa6";
import { FaFilter } from "react-icons/fa";

import { MapContainer, TileLayer, Marker, Popup, Circle } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";

import { HiOutlineSortAscending } from "react-icons/hi";
import { HiOutlineSortDescending } from "react-icons/hi";

const ViewRoutes = ({ calculatedRoute, handleApproveRoute }) => {
  const lib = ["places"];

  const center = {
    lat: 39.8283,
    lng: -96.795,
  };

  // Attempt to parse assignedRoute safely
  const getAssignedRoute = (routeData) => {
    try {
      if (typeof routeData.assignedRoute === "string") {
        return JSON.parse(routeData.assignedRoute);
      }
      return routeData.assignedRoute;
    } catch (error) {
      console.error("Error parsing assignedRoute:", error);
      return null;
    }
  };
  const [filteredStations, setFilteredStations] = useState([]);

  const [route, setRoute] = useState(calculatedRoute || {});
  const [selectedRoute, setSelectedRoute] = useState(getAssignedRoute(calculatedRoute) || {});
  const [fileNames, setFileNames] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [isUSRegion, setIsUSRegion] = useState(true);
  const [sortOrder, setSortOrder] = useState("asc");
  const [filteredFileNames, setFilteredFileNames] = useState([]);
  const [priceCol, setPriceCol] = useState("Your Price");
  const [radius, setRadius] = useState(200000);
  const [selectedDate, setSelectedDate] = useState("US_2024-02-08");
  const [isGraphVisible, setGraphVisible] = useState(false);
  const [loadingMap, setLoadingMap] = useState(true);

  const [selectedMarker, setSelectedMarker] = useState(null);
  const [desiredPrice, setDesiredPrice] = useState(3.0);
  const [maxPrice, setMaxPrice] = useState(desiredPrice * 1.1);
  const [filteredWaypoints, setFilteredWaypoints] = useState([]);

  useEffect(() => {
    setLoadingMap(true);
    if (calculatedRoute) {
      setRoute(calculatedRoute);
      setSelectedRoute(getAssignedRoute(calculatedRoute) || {});
      setLoadingMap(false);
    }
  }, [calculatedRoute]);

  const isDateWithinRange = (fileName, dateToSearch) => {
    const dateParts = fileName.match(/\d{4}-\d{2}-\d{2}/g);
    if (dateParts.length === 1) {
      return dateParts[0].includes(dateToSearch);
    }
    if (dateParts.length === 2) {
      const [startDate, endDate] = dateParts;
      return new Date(startDate) <= new Date(dateToSearch) && new Date(dateToSearch) <= new Date(endDate);
    }
    return false;
  };

  useEffect(() => {
    let filtered = fileNames;
    filtered = filtered.filter((fileName) => (isUSRegion ? fileName.startsWith("US") : fileName.startsWith("CA")));

    if (searchTerm) {
      filtered = filtered.filter((fileName) => {
        if (fileName.includes("to")) {
          return isDateWithinRange(fileName, searchTerm);
        }
        return fileName.includes(searchTerm);
      });
    }

    filtered.sort((a, b) => {
      const aDate = a.match(/\d{4}-\d{2}-\d{2}/)[0];
      const bDate = b.match(/\d{4}-\d{2}-\d{2}/)[0];
      return sortOrder === "asc" ? new Date(aDate) - new Date(bDate) : new Date(bDate) - new Date(aDate);
    });

    setFilteredFileNames(filtered);
  }, [fileNames, searchTerm, isUSRegion, sortOrder]);

  const handleConfigureClick = (routeId) => {
    window.location.href = `/cost/configureRoute?routeId=${routeId}`;
  };

  const fetchFileNames = async () => {
    try {
      const response = await retrievePriceDates();
      if (response.status === 200) {
        setFileNames(response.data.availableDates);
      } else {
        console.error(`Error fetching file names: ${response.message}`);
      }
    } catch (error) {
      console.error(`Error fetching file names: ${error.message}`);
    }
  };

  useEffect(() => {
    fetchFileNames();
  }, [route]);

  useEffect(() => {
    if (selectedRoute) {
      if (selectedRoute.radius_filtered_stations) {
        setFilteredStations(selectedRoute.radius_filtered_stations);
      } else {
        setFilteredStations([]);
      }

      if (selectedRoute.radius_filtered_waypoints) {
        setFilteredWaypoints(selectedRoute.radius_filtered_waypoints);
      } else {
        setFilteredWaypoints([]);
      }

      if (selectedRoute.radius_filtered_stations) {
        const priceValues = selectedRoute.radius_filtered_stations.map((station) => station[1]);
        if (priceValues.length > 0) {
          const totalPrice = priceValues.reduce((acc, price) => acc + price, 0);
          const meanPrice = totalPrice / priceValues.length;

          setDesiredPrice(meanPrice);
        }
      }
    } else {
      setFilteredStations([]);
      setFilteredWaypoints([]);
    }
  }, [selectedRoute]);

  const handleMarkerClick = (location) => {
    setSelectedMarker(location);
  };

  const handleInfoWindowCloseClick = () => {
    setSelectedMarker(null);
  };

  const lightStyleUrl = "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png";

  const createMarkerIcon = (color) => {
    const svgIcon = `
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="30" height="30">
        <circle cx="12" cy="12" r="7" fill="${color}" stroke="#fff" stroke-width="2"/>
      </svg>
    `;
    return new L.DivIcon({
      className: "custom-icon",
      html: svgIcon,
      iconSize: [30, 30],
      iconAnchor: [15, 30],
    });
  };
  const createTriangleIcon = (color, letter) => {
    return new L.DivIcon({
      className: "custom-icon",
      html: `<div style="width: 0; height: 0; border-left: 18px solid transparent; border-right: 18px solid transparent; border-top: 36px solid ${color}; z-index: 999; display: flex; align-items: center; justify-content: center; font-size: 20px; font-weight: bold; position: relative;">
               <div style="position: absolute; top: 0; color: white; font-size: 20px; font-weight: bold; transform: translateY(-125%);">
                 ${letter}
               </div>
             </div>`,
      iconSize: [40, 50],
      iconAnchor: [20, 50],
    });
  };

  const getMatrixStopLetter = (index) => {
    return String.fromCharCode(65 + index);
  };

  const getMarkerColor = (price) => {
    if (price < desiredPrice) return "#28a745";
    if (price <= maxPrice) return "#ff9800";
    return "#f44336";
  };

  return (
    <div className={styles.viewRoutesContainer}>
      <div className={styles.viewRoutesOverview}>
        <div className={styles.leftContainer}>
          <div className={styles.viewRoutesMainContainer}>
            <div>
              <div className={styles.viewRoutesOverview}>
                <div className={styles.left}>
                  <b>Driver:</b> {route.driverName || "Driver Name"}
                </div>
                <div className={styles.center}></div>
                <div className={styles.right}></div>
              </div>
              <div className={styles.viewRoutesOverview}>
                <div className={styles.left}>
                  {" "}
                  <b>Vehicle:</b> {route.vehicleNo}
                </div>
                <div className={styles.center}>MPG: {route.fuelEconomy}</div>
                <div className={styles.right}>
                  Gas Level: {route.currentGas}/{route.tankCapacity} gal
                </div>
              </div>
              <div className={styles.viewRoutesOverview}>
                <div className={styles.left}>Max Stops: {route.maxStops} Stops</div>
                <div className={styles.center}></div>
              </div>
            </div>
            <div className={styles.viewRoutesDivider}></div>
            <div className={styles.dateContainer}>
              <div className={styles.searchContainer}>
                <input type="text" placeholder="Search by date (e.g., 2023-03-30)" value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
              </div>

              <div className={styles.filterContainer}>
                <label>
                  <input type="radio" checked={isUSRegion} onChange={() => setIsUSRegion(true)} /> US
                </label>
                <label>
                  <input type="radio" checked={!isUSRegion} onChange={() => setIsUSRegion(false)} /> CA
                </label>
              </div>

              <div className={styles.sortContainer}>
                <button onClick={() => setSortOrder("asc")} className={sortOrder === "asc" ? styles.active : ""}>
                  <HiOutlineSortAscending color="black" size={"25px"} />
                </button>
                <button onClick={() => setSortOrder("desc")} className={sortOrder === "desc" ? styles.active : ""}>
                  <HiOutlineSortDescending color="black" size={"25px"} />
                </button>
              </div>
            </div>

            <div className={styles.fileListContainer}>
              <select className={styles.selectDateDropdown} value={selectedDate} onChange={(e) => setSelectedDate(e.target.value)}>
                <option value>Select a date</option>
                {filteredFileNames.map((fileName) => (
                  <option key={fileName} value={fileName}>
                    {fileName}
                  </option>
                ))}
              </select>
              <select className={styles.selectPriceColDropdown} value={priceCol} onChange={(e) => setPriceCol(e.target.value)}>
                <option value="Retail Price">Retail Price</option>
                <option value="Your Price">Your Price</option>
              </select>
              <div className={styles.radiusInputGroup}>
                <label htmlFor="radius-slider">Radius: {radius} meters</label>
                <input id="radius-slider" type="range" min="0" max="500000" step="1000" value={radius} onChange={(e) => setRadius(Number(e.target.value))} className={styles.radiusSlider} />
              </div>
            </div>
            <div className={styles.viewRoutesDivider}></div>

            <div className={styles.viewRoutesMatrixContainer}>
              <div className={styles.routesMatrix}>
                <RoutesMatrix routeData={selectedRoute} />
              </div>{" "}
              <div className={styles.viewRoutesContainerTitle}>
                <b>Source:</b> {route.startAddress} <br /> <b>Destination:</b> {route.endAddress}
                <div className={styles.actionButtonContainer}>
                  <button disabled={true} className={styles.configureButton} onClick={() => handleConfigureClick(route.routeId)}>
                    Configure
                  </button>
                  <button disabled={true} className={styles.routeManageButton}>
                    Reassign Driver
                  </button>
                </div>
              </div>
            </div>

            {selectedRoute && (
              <>
                <div className={styles.viewRoutesDivider}></div>
                <div className={styles.routeDetailsContainer}>
                  {" "}
                  <div className={styles.viewRoutesAddressTitle}>Directions:</div>
                  <div>
                    <button className={styles.toggleButton} onClick={() => setGraphVisible(!isGraphVisible)}>
                      {isGraphVisible ? <FaChartLine size={"20px"} opacity={0.5} color="#0245be" /> : <FaChartLine size={"20px"} color="#0245be" opacity={1} />}
                    </button>{" "}
                    <div className={styles.priceFilterDropdown}>
                      <button className={styles.filterButton}>
                        <FaFilter size="16px" />
                      </button>
                      <div className={styles.dropdownContent}>
                        <div className={styles.sliderGroup}>
                          <label htmlFor="desired-price-slider">Desired Price: ${desiredPrice.toFixed(2)}</label>
                          <input
                            id="desired-price-slider"
                            type="range"
                            min="1"
                            max="10"
                            step="0.01"
                            value={desiredPrice}
                            onChange={(e) => {
                              const newDesiredPrice = Number(e.target.value);
                              setDesiredPrice(newDesiredPrice);
                              setMaxPrice(newDesiredPrice * 1.1);
                            }}
                          />
                        </div>
                        <div className={styles.sliderGroup}>
                          <label htmlFor="max-price-slider">Max Price: ${maxPrice.toFixed(2)}</label>
                          <input id="max-price-slider" type="range" min={desiredPrice} max="10" step="0.01" value={maxPrice} onChange={(e) => setMaxPrice(Number(e.target.value))} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {isGraphVisible && (
                  <div className={styles.graphContainer}>
                    {" "}
                    <GraphComponent minReserve={route.minReserve} selectedRoute={selectedRoute} tankCapacity={route.tankCapacity} fuelEconomy={route.fuelEconomy} gasLevel={route.currentGas} />
                  </div>
                )}
                <div className={styles.viewRoutesAddressContainer}>
                  {selectedRoute != null &&
                    selectedRoute.stop_details.map((stop, index) => (
                      <div key={index} className={styles.viewRoutesAddressLine}>
                        <div>
                          <span className={styles.viewRoutesLabel}>{index === 0 ? "Start:" : index === selectedRoute.stop_details.length - 1 ? "Destination:" : `Stop ${index}:`}</span> {stop.address}
                        </div>
                        {stop.amountFill != "0.0 gal" && (
                          <>
                            {stop.amountFill} @ {stop.stopPrice}
                          </>
                        )}
                      </div>
                    ))}
                </div>
              </>
            )}
          </div>
        </div>
        <div className={styles.rightContainer}>
          <>
            <MapContainer key={`${JSON.stringify(selectedRoute)}-${radius}`} center={center} zoom={4} style={{ width: "100%", height: "50%" }}>
              <TileLayer url={lightStyleUrl} />

              {filteredStations &&
                filteredStations.map((location, index) => {
                  if (!location || location.length < 4) return null;

                  const price = location[1];
                  const color = getMarkerColor(price);

                  const matchingMatrixStop = selectedRoute.gaddi_matrix.find((matrixStop, matrixIndex) => {
                    return matrixStop[1] === location[2] && matrixStop[2] === location[3];
                  });

                  if (matchingMatrixStop) {
                    const matrixIndex = selectedRoute.gaddi_matrix.indexOf(matchingMatrixStop);
                    const letter = getMatrixStopLetter(matrixIndex);

                    return (
                      <Marker
                        key={`station-${location[0]}-${index}`}
                        position={{ lat: location[2], lng: location[3] }}
                        icon={createTriangleIcon("#f44336", letter)}
                        eventHandlers={{
                          click: () => handleMarkerClick(location),
                        }}
                        zIndexOffset={100}
                      >
                        <Popup>
                          <div>
                            <p>ID: {location[0]}</p>
                            <p>Price: ${price.toFixed(4)}</p>
                            <p>Latitude: {location[2]}</p>
                            <p>Longitude: {location[3]}</p>
                            <p>Amount to Fill: {matchingMatrixStop[4]} gal</p>
                          </div>
                        </Popup>
                      </Marker>
                    );
                  }

                  return (
                    <Marker
                      key={`station-${location[0]}-${index}`}
                      position={{ lat: location[2], lng: location[3] }}
                      icon={createMarkerIcon(color)}
                      eventHandlers={{
                        click: () => handleMarkerClick(location),
                      }}
                      pathOptions={{ zIndexOffset: 0 }}
                    >
                      <Popup>
                        <div>
                          <p>ID: {location[0]}</p>
                          <p>Price: ${price.toFixed(4)}</p>
                          <p>Latitude: {location[2]}</p>
                          <p>Longitude: {location[3]}</p>
                        </div>
                      </Popup>
                    </Marker>
                  );
                })}

              {selectedRoute.gaddi_matrix && selectedRoute.gaddi_matrix.length > 0 && (
                <>
                  <Marker
                    key="start-marker"
                    position={{
                      lat: selectedRoute.gaddi_matrix[0][1],
                      lng: selectedRoute.gaddi_matrix[0][2],
                    }}
                    icon={createTriangleIcon("#f44336", getMatrixStopLetter(0))}
                    zIndexOffset={200}
                  >
                    <Popup>
                      <div>
                        <p>Start</p>
                        <p>Latitude: {selectedRoute.gaddi_matrix[0][1]}</p>
                        <p>Longitude: {selectedRoute.gaddi_matrix[0][2]}</p>
                      </div>
                    </Popup>
                  </Marker>

                  <Marker
                    key="destination-marker"
                    position={{
                      lat: selectedRoute.gaddi_matrix[selectedRoute.gaddi_matrix.length - 1][1],
                      lng: selectedRoute.gaddi_matrix[selectedRoute.gaddi_matrix.length - 1][2],
                    }}
                    icon={createTriangleIcon("#f44336", getMatrixStopLetter(selectedRoute.gaddi_matrix.length - 1))}
                    zIndexOffset={200}
                  >
                    <Popup>
                      <div>
                        <p>Destination</p>
                        <p>Latitude: {selectedRoute.gaddi_matrix[selectedRoute.gaddi_matrix.length - 1][1]}</p>
                        <p>Longitude: {selectedRoute.gaddi_matrix[selectedRoute.gaddi_matrix.length - 1][2]}</p>
                      </div>
                    </Popup>
                  </Marker>
                </>
              )}

              {filteredWaypoints &&
                filteredWaypoints.map((waypoint, index) => {
                  if (!waypoint || waypoint.length < 2) return null;

                  return (
                    <Circle
                      key={`waypoint-${waypoint[0]}-${waypoint[1]}-${index}-${radius}`}
                      center={{ lat: waypoint[0], lng: waypoint[1] }}
                      radius={radius}
                      pathOptions={{
                        stroke: true,
                        color: "#386498",
                        weight: 1,
                        opacity: 0.8,
                        fillColor: "#386498",
                        fillOpacity: 0.05,
                      }}
                    />
                  );
                })}

              {selectedMarker && (
                <Popup position={{ lat: selectedMarker[2], lng: selectedMarker[3] }} onClose={handleInfoWindowCloseClick}>
                  <div>
                    <p>ID: {selectedMarker[0]}</p>
                    <p>Latitude: {selectedMarker[2]}</p>
                    <p>Longitude: {selectedMarker[3]}</p>
                    <p>Price: ${selectedMarker[1].toFixed(4)}</p>
                  </div>
                </Popup>
              )}
            </MapContainer>

            <div className={styles.delayedOpacity} key={selectedRoute.webview_embed_link}>
              <iframe src={selectedRoute.webview_embed_link} style={{ width: "100%", height: "50vh", border: "none" }} />
            </div>
          </>
        </div>
      </div>
    </div>
  );
};

export default ViewRoutes;
