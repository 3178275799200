import React, { useEffect, useState } from "react";
import styles from "./ConfirmModal.module.css";

const ConfirmModal = ({ title, text, onConfirm, iconButton: IconButton }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isStyleUpdated, setIsStyleUpdated] = useState(false);

  useEffect(() => {
    setIsStyleUpdated(isOpen);
  }, [isOpen]);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const handleConfirm = () => {
    onConfirm();
    closeModal();
  };

  return (
    <div>
      <button className={styles.iconButton} onClick={openModal}>
        {IconButton && <IconButton />}
      </button>
      {isOpen && (
        <div className={`${styles.modalOverlay} ${isStyleUpdated ? styles.open : ""}`}>
          <div className={`${styles.modal} ${isStyleUpdated ? styles.open : ""}`}>
            <div className={styles.modalContent}>
              <h2>{title}</h2>
              <p>{text}</p>
              <div className={styles.buttonContainer}>
                <button onClick={closeModal} className={styles.cancelButton}>
                  Cancel
                </button>
                <button onClick={handleConfirm} className={styles.confirmButton}>
                  {title}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ConfirmModal;
