import React, { useState, useEffect } from "react";
import { createDriver, editDriver, deleteDriver, getAllDrivers } from "../helpers/driverHelper";
import { getAllVehicles } from "../helpers/vehicleHelper";
import ConfirmModal from "../shared/ConfirmModal";
import { FaPlus, FaEdit, FaTrash } from "react-icons/fa";
import styles from "./Drivers.module.css";

const Drivers = () => {
  const [drivers, setDrivers] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const [editing, setEditing] = useState(null);
  const [newDriver, setNewDriver] = useState({
    driverName: "",
    driverMobile: "",
    truckId: "",
    truckName: "",
    username: "",
    password: "",
  });
  const [editData, setEditData] = useState({});
  const [showForm, setShowForm] = useState(false);
  const [message, setMessage] = useState("");

  // Fetch drivers and vehicles on component mount
  useEffect(() => {
    const fetchDrivers = async () => {
      const response = await getAllDrivers();
      if (response.status === 200) {
        setDrivers(response.data.drivers);
      } else {
        alert("Failed to fetch drivers");
      }
    };

    const fetchVehicles = async () => {
      const response = await getAllVehicles();
      if (response.status === 200) {
        setVehicles(response.data.vehicles);
      } else {
        alert("Failed to fetch vehicles");
      }
    };

    fetchDrivers();
    fetchVehicles();
  }, []);

  // Handle new driver creation
  const handleCreate = async () => {
    const selectedVehicle = vehicles.find((vehicle) => vehicle.vehicleId === newDriver.truckId);
    const driverData = {
      ...newDriver,
      truckName: selectedVehicle ? selectedVehicle.name : "",
    };

    const response = await createDriver(driverData);
    if (response.status === 200) {
      setMessage("Driver created successfully!");
      setNewDriver({ driverName: "", driverMobile: "", truckId: "", truckName: "", username: "", password: "" });
      setShowForm(false);
      fetchDrivers();
    } else {
      alert("Failed to create driver");
    }
  };

  // Handle editing a driver
  const handleEdit = (driver) => {
    setEditing(driver.driverId);
    setEditData({
      ...driver,
      truckName: vehicles.find((vehicle) => vehicle.vehicleId === driver.truckId)?.name || "Unassigned",
    });
  };

  // Save edited driver
  const handleSave = async () => {
    const selectedVehicle = vehicles.find((vehicle) => vehicle.vehicleId === editData.truckId);
    const updatedData = {
      ...editData,
      truckName: selectedVehicle ? selectedVehicle.name : "",
    };

    const response = await editDriver(editing, updatedData);
    if (response.status === 200) {
      setMessage("Driver updated successfully!");
      setDrivers(drivers.map((driver) => (driver.driverId === editing ? response.data : driver)));
      setEditing(null);
      fetchDrivers();
    } else {
      alert("Failed to edit driver");
    }
  };

  // Handle deleting a driver
  const handleDelete = async (driverId) => {
    const response = await deleteDriver(driverId);
    if (response.status === 200) {
      setMessage("Driver deleted successfully!");
      fetchDrivers();
    } else {
      alert("Failed to delete driver");
    }
  };

  // Refetch drivers
  const fetchDrivers = async () => {
    const response = await getAllDrivers();
    if (response.status === 200) {
      setDrivers(response.data.drivers);
    } else {
      alert("Failed to fetch drivers");
    }
  };

  const deleteIcon = () => <FaTrash color="#d15533" size="20px" />;

  return (
    <div className={styles.driversContainer}>
      <div className={styles.title}>
        Driver Management
        <button className={styles.addButton} onClick={() => setShowForm(!showForm)}>
          <FaPlus style={{ marginRight: "5px" }} /> Add New Driver
        </button>
      </div>
      {showForm && (
        <div className={styles.section}>
          <div className={styles.field}>
            <label className={styles.label}>Driver Name</label>
            <input className={styles.input} type="text" value={newDriver.driverName} onChange={(e) => setNewDriver({ ...newDriver, driverName: e.target.value })} />
          </div>
          <div className={styles.field}>
            <label className={styles.label}>Driver Mobile</label>
            <input className={styles.input} type="text" value={newDriver.driverMobile} onChange={(e) => setNewDriver({ ...newDriver, driverMobile: e.target.value })} />
          </div>
          <div className={styles.field}>
            <label className={styles.label}>Username</label>
            <input className={styles.input} type="text" value={newDriver.username} onChange={(e) => setNewDriver({ ...newDriver, username: e.target.value })} />
          </div>
          <div className={styles.field}>
            <label className={styles.label}>Password</label>
            <input className={styles.input} type="password" value={newDriver.password} onChange={(e) => setNewDriver({ ...newDriver, password: e.target.value })} />
          </div>
          <div className={styles.field}>
            <label className={styles.label}>Assign Vehicle</label>
            <select
              className={styles.input}
              value={newDriver.truckId}
              onChange={(e) => {
                const selectedVehicle = vehicles.find((vehicle) => vehicle.vehicleId === e.target.value);
                setNewDriver({
                  ...newDriver,
                  truckId: e.target.value,
                  truckName: selectedVehicle ? selectedVehicle.name : "",
                });
              }}
            >
              <option value="">Select Vehicle</option>
              {vehicles.map((vehicle) => (
                <option key={vehicle.vehicleId} value={vehicle.vehicleId}>
                  {vehicle.name}
                </option>
              ))}
            </select>
          </div>
          <div className={styles.actions}>
            <button className={styles.button} onClick={handleCreate}>
              Create Driver
            </button>
          </div>
        </div>
      )}
      <div className={styles.divider}></div>
      <div className={styles.driverDetailsHeader}>
        <div>Driver Name</div>
        <div>Mobile</div>
        <div>Vehicle</div>
        <div>Username</div>
        <div>Password</div>
      </div>
      <div className={styles.detailsContainer}>
        {drivers.map((driver) => (
          <div key={driver.driverId} className={styles.driverItem}>
            {editing === driver.driverId ? (
              <>
                <div className={styles.field}>
                  <label className={styles.label}>Driver Name</label>
                  <input className={styles.input} type="text" value={editData.driverName} onChange={(e) => setEditData({ ...editData, driverName: e.target.value })} />
                </div>
                <div className={styles.field}>
                  <label className={styles.label}>Driver Mobile</label>
                  <input className={styles.input} type="text" value={editData.driverMobile} onChange={(e) => setEditData({ ...editData, driverMobile: e.target.value })} />
                </div>
                <div className={styles.field}>
                  <label className={styles.label}>Username</label>
                  <input className={styles.input} type="text" value={editData.username} onChange={(e) => setEditData({ ...editData, username: e.target.value })} />
                </div>
                <div className={styles.field}>
                  <label className={styles.label}>Password</label>
                  <input className={styles.input} type="text" value={editData.password} onChange={(e) => setEditData({ ...editData, password: e.target.value })} />
                </div>
                <div className={styles.field}>
                  <label className={styles.label}>Assign Vehicle</label>
                  <select
                    className={styles.input}
                    value={editData.truckId}
                    onChange={(e) => {
                      const selectedVehicle = vehicles.find((vehicle) => vehicle.vehicleId === e.target.value);
                      setEditData({
                        ...editData,
                        truckId: e.target.value,
                        truckName: selectedVehicle ? selectedVehicle.name : "",
                      });
                    }}
                  >
                    <option value="">Select Vehicle</option>
                    {vehicles.map((vehicle) => (
                      <option key={vehicle.vehicleId} value={vehicle.vehicleId}>
                        {vehicle.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className={styles.actions}>
                  <button className={styles.button} onClick={handleSave}>
                    Save
                  </button>
                  <button className={styles.button} onClick={() => setEditing(null)}>
                    Cancel
                  </button>
                </div>
              </>
            ) : (
              <>
                <div className={styles.driverDetails}>
                  <div>{driver.driverName}</div>
                  <div>{driver.driverMobile}</div>
                  <div>{driver.truckName || "Unassigned"}</div>
                  <div>{driver.username || "N/A"}</div>
                  <div>{driver.password || "N/A"}</div>
                </div>
                <div className={styles.actions}>
                  <button className={styles.iconButton}>
                    <FaEdit color="#d15533" size="20px" onClick={() => handleEdit(driver)} />
                  </button>
                  <ConfirmModal title="Delete" text="Are you sure you want to delete this driver?" onConfirm={() => handleDelete(driver.driverId)} iconButton={deleteIcon} />
                </div>
              </>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Drivers;
