import React, { useState, useEffect } from "react";
import { formatDate } from "../../utils/costUtils/formatDate";
import styles from "./Organization.module.css";
import { Spinner } from "../shared/Spinner";
import { AuthMeRequest } from "../helpers/authHelper";
import { editOrganizationInfo, changeOrganizationApiKeys } from "../helpers/organizationHelper";

const Organization = () => {
  const [organizationDetails, setOrganizationDetails] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const [apiKeys, setApiKeys] = useState({
    dispatchApiKey: "",
    fleetApiKey: "",
    fuelApiKey: "",
  });
  const [editingApiKeys, setEditingApiKeys] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchDetails = async () => {
      const response = await AuthMeRequest();
      if (response.status === 200) {
        setOrganizationDetails(response.organizationInfo);
        setApiKeys({
          dispatchApiKey: response.organizationInfo.dispatchApiKey ? "••••••••••••••••••••••••••••••••••••••••••••" + response.organizationInfo.dispatchApiKey : "",
          fleetApiKey: response.organizationInfo.fleetApiKey ? "••••••••••••••••••••••••••••••••••••••••••••" + response.organizationInfo.fleetApiKey : "",
          fuelApiKey: response.organizationInfo.fuelApiKey ? "••••••••••••••••••••••••••••••••••••••••••••" + response.organizationInfo.fuelApiKey : "",
        });
      }
      setLoading(false);
    };
    fetchDetails();
  }, []);

  const handleEditClick = () => {
    setIsEditing(!isEditing);
  };

  const handleSaveClick = async () => {
    const response = await editOrganizationInfo(organizationDetails);
    if (response.status === 200) {
      setIsEditing(false);
    }
  };

  const handleApiKeyInputChange = (key, value) => {
    setApiKeys((prevKeys) => ({ ...prevKeys, [key]: value }));
  };

  const handleApiKeyChange = async () => {
    const response = await changeOrganizationApiKeys(apiKeys);
    if (response.status === 200) {
      setEditingApiKeys(false);
    }
    console.log(response);
  };

  return (
    <div className={styles.organizationContainer}>
      {loading ? (
        <Spinner />
      ) : (
        <>
          <div className={styles.section}>
            <div className={styles.title}>{organizationDetails.name || "Organization"} Details</div>
            <div className={styles.field}>
              <label className={styles.label}>Organization Name:</label>
              <input className={styles.input} type="text" value={organizationDetails.name || ""} onChange={(e) => setOrganizationDetails({ ...organizationDetails, name: e.target.value })} disabled={!isEditing} />
            </div>
            <div className={styles.field}>
              <label className={styles.label}>Address:</label>
              <textarea className={styles.textarea} value={organizationDetails.address || ""} onChange={(e) => setOrganizationDetails({ ...organizationDetails, address: e.target.value })} disabled={!isEditing} />
            </div>
            <div className={styles.field}>
              <label className={styles.label}>Phone Number:</label>
              <input className={styles.input} value={organizationDetails.phoneNumber || ""} onChange={(e) => setOrganizationDetails({ ...organizationDetails, phoneNumber: e.target.value })} disabled={!isEditing} />
            </div>
            <div className={styles.actions}>
              {isEditing ? (
                <>
                  <button className={styles.button} onClick={handleSaveClick}>
                    Save
                  </button>
                  <button className={styles.button} onClick={handleEditClick}>
                    Cancel
                  </button>
                </>
              ) : (
                <button className={styles.button} onClick={handleEditClick}>
                  Edit
                </button>
              )}
            </div>
          </div>
          <div className={styles.divider} />
          <div className={styles.section}>
            <div className={styles.title}>Account Info</div>
            <div className={styles.inputs}>
              <div className={styles.label}>Created On: {formatDate(organizationDetails.createdAt)}</div>
              <div className={styles.label}>Last Updated On: {formatDate(organizationDetails.updatedAt)}</div>
              <div className={styles.label} style={{ marginTop: "15px" }}>
                Email:
              </div>
              <input className={styles.input} type="text" value={organizationDetails.email || ""} disabled />
              <div className={styles.actions}>
                <button className={styles.button}>Change Password</button>
                <button className={styles.button}>Change Email</button>
              </div>
            </div>
          </div>
          <div className={styles.divider} />
          <div className={styles.section}>
            <div className={styles.title}>API Keys</div>
            {["dispatchApiKey", "fleetApiKey", "fuelApiKey"].map((key) => (
              <div key={key} className={styles.field}>
                <label className={styles.label}>{key.replace(/ApiKey/, " Key")}:</label>
                <input className={styles.input} type="text" value={apiKeys[key]} onChange={(e) => handleApiKeyInputChange(key, e.target.value)} />
              </div>
            ))}
            <div className={styles.actions}>
              <button
                onClick={() => {
                  if (editingApiKeys) {
                    handleApiKeyChange();
                  }
                  setEditingApiKeys(!editingApiKeys);
                }}
                className={styles.button}
              >
                {editingApiKeys ? "Save API Keys" : "Edit API Keys"}
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Organization;
