import "../style/components-style/NetZeroBottomTool.css"
import {Line} from "react-chartjs-2";
import React from "react";


function Scope12ShortTermTool({
                                  resetForm,
                                  saveShortTermToolInputs,
                                  baseYear,
                                  setBaseYear,
                                  baseYearList,
                                  targetYear,
                                  setTargetYear,
                                  targetYearList,
                                  mostRecentYear,
                                  setMostRecentYear,
                                  mostRecentYearList,
                                  scope1Emission,
                                  scope2Emission,
                                  MRYScope1Emission,
                                  setMRYScope1Emission,
                                  MRYScope2Emission,
                                  setMRYScope2Emission,
                                  scope1TargetEmission,
                                  scope2TargetEmission,
                                  yearsList,
                                  shortTermGraphRef,
                                  shortTermGraphData,
                                  shortTermGraphOptions
                              }) {
    return (
        <>
            <div className="section">
                <div className="freight-tool-button-container">
                    <button className="freight-tool-reset-button" onClick={resetForm}>Reset</button>
                    <button className="freight-tool-save-button" onClick={saveShortTermToolInputs}>Save</button>
                </div>
                <h3>Input data</h3>
                <div className="data-table">
                    <div className="table-row">
                        <div className="row label">Target setting method</div>
                        <div className="input grey">Absolute Contraction Approach</div>
                    </div>
                    <div className="table-row">
                        <div className="row label">Base year</div>
                        <select
                            className="input grey"
                            value={baseYear}
                            onChange={(e) => setBaseYear(e.target.value)}
                        >
                            <option value="">Select a Year</option>
                            {baseYearList.map((year, index) => (
                                <option key={index} value={year}>{year}</option>
                            ))}
                        </select>
                    </div>
                    <div className="table-row">
                        <div className="row label">Base Year | Scope 1 emissions (tCO₂e)</div>
                        <div className="input grey">{scope1Emission.toFixed(2)}</div>
                    </div>
                    <div className="table-row">
                        <div className="row label">Base Year | Scope 2 emissions (tCO₂e)</div>
                        <div className="input grey">{scope2Emission.toFixed(2)}</div>
                    </div>
                    <div className="table-row">
                        <div className="row label">Target year</div>
                        <select
                            className="input grey"
                            value={targetYear}
                            onChange={(e) => setTargetYear(e.target.value)}
                        >
                            <option value="">Select a Year</option>
                            {targetYearList.map((year, index) => (
                                <option key={index} value={year}>{year}</option>
                            ))}
                        </select>
                    </div>
                    <div className="table-row">
                        <div className="row label">Most Recent year</div>
                        <select
                            className="input grey"
                            value={mostRecentYear}
                            onChange={(e) => setMostRecentYear(e.target.value)}
                        >
                            <option value="">Select a Year</option>
                            {mostRecentYearList.map((year, index) => (
                                <option key={index} value={year}>{year}</option>
                            ))}
                        </select>
                    </div>
                    <div className="table-row">
                        <div className="row label">MRY | Scope 1 emissions (tCO₂e)</div>
                        <input
                            type="number"
                            className="input grey"
                            value={MRYScope1Emission}
                            onChange={(e) => setMRYScope1Emission(Number(e.target.value))}
                            inputMode="numeric"
                            pattern="[0-9]*"
                        />
                    </div>
                    <div className="table-row">
                        <div className="row label">MRY | Scope 2 emissions (tCO₂e)</div>
                        <input
                            type="number"
                            className="input grey"
                            value={MRYScope2Emission}
                            onChange={(e) => setMRYScope2Emission(Number(e.target.value))}
                            inputMode="numeric"
                            pattern="[0-9]*"
                        />
                    </div>
                </div>
            </div>

            <div className="section">
                <h3>Cross-sector absolute reduction / Absolute contraction approach (ACA)</h3>
                <div className="data-table">
                    <div className="table-row table-header">
                        <div className="header-column"></div>
                        <div className="header-column">Base year ({baseYear})</div>
                        <div className="header-column">Most Recent year ({mostRecentYear})</div>
                        <div className="header-column">Target year ({targetYear})</div>
                        <div className="header-column">% Reduction to Date</div>
                        {/*<div className="header-column">% FLA Adjustment</div>*/}
                        <div className="header-column">% SBT Reduction</div>
                    </div>

                    <div className="table-row">
                        <div className="row label">Scope 1 emissions (tCO2e)</div>
                        <div className="input grey">{scope1Emission.toFixed(2)}</div>
                        <div className="input grey">{MRYScope1Emission.toFixed(2)}</div>
                        <div className="input grey">{scope1TargetEmission.toFixed(2)}</div>
                        <div
                            className="input grey">{((1 - (MRYScope1Emission / scope1Emission)) * 100).toFixed(2)}%
                        </div>
                        {/*<div className="input grey">{computeFLA("scope1")}</div>*/}
                        <div className="input grey">{(((yearsList.length - 1) * 0.042) * 100).toFixed(2)}%</div>
                    </div>

                    <div className="table-row">
                        <div className="row label">Scope 2 emissions (tCO2e)</div>
                        <div className="input grey">{scope2Emission.toFixed(2)}</div>
                        <div className="input grey">{MRYScope2Emission.toFixed(2)}</div>
                        <div className="input grey">{scope2TargetEmission.toFixed(2)}</div>
                        <div
                            className="input grey">{((1 - (MRYScope2Emission / scope2Emission)) * 100).toFixed(2)}%
                        </div>
                        {/*<div className="input grey">{computeFLA("scope2")}</div>*/}
                        <div className="input grey">{(((yearsList.length - 1) * 0.042) * 100).toFixed(2)}%</div>
                    </div>

                    <div className="table-row">
                        <div className="row label">Scope 1 + 2 emissions (tCO2e)</div>
                        <div className="input grey">{(scope1Emission + scope2Emission).toFixed(2)}</div>
                        <div className="input grey">{(MRYScope1Emission + MRYScope2Emission).toFixed(2)}</div>
                        <div className="input grey">{(scope1TargetEmission + scope2TargetEmission).toFixed(2)}</div>
                        <div
                            className="input grey">{((1 - ((MRYScope1Emission + MRYScope2Emission) / (scope1Emission + scope2Emission))) * 100).toFixed(2)}%
                        </div>
                        {/*<div className="input grey">{computeFLA("scope1 + 2")}</div>*/}
                        <div className="input grey">{(((yearsList.length - 1) * 0.042) * 100).toFixed(2)}%</div>
                    </div>
                </div>
            </div>
            <div className="freight-tool-chart-container">
                <div ref={shortTermGraphRef} className="freight-tool-chart-box">
                    <h3>Short Term Emissions</h3>
                    <Line data={shortTermGraphData}
                          options={shortTermGraphOptions}/>
                </div>
            </div>
        </>
    )
}

export default Scope12ShortTermTool;