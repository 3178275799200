import { useState } from "react";
import withAuth from "./shared/withAuth";
import styles from "./Settings.module.css";

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

import Header from "../app_components/Header";

import Organization from "./settings-components/Organization";
import Fuel from "./settings-components/Fuel";
import Fleet from "./settings-components/Fleet";
import RoutePlanning from "./settings-components/RoutePlanning";
import Drivers from "./settings-components/Drivers";
import CustomerManagement from "./settings-components/CustomerManagement";

const CostSettings = () => {
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabSelect = (index) => {
    setSelectedTab(index);
  };

  return (
    <div className={styles.settingsMainContainer}>
      <Header selectedLanguage="en" />
      <Tabs selectedIndex={selectedTab} onSelect={handleTabSelect}>
        <div className={styles.headerContainer}>
          <TabList className={styles.tablist}>
            <Tab className={`${styles.tab} ${selectedTab === 0 ? styles.selected : ""}`}>
              <div className={styles.tabContent}>Overview </div>
            </Tab>
            <span className={styles.tabDivider}>|</span>
            <Tab className={`${styles.tab} ${selectedTab === 1 ? styles.selected : ""}`}>
              <div className={styles.tabContent}>Fleet Details </div>
            </Tab>
            <span className={styles.tabDivider}>|</span>
            <Tab className={`${styles.tab} ${selectedTab === 2 ? styles.selected : ""}`}>
              <div className={styles.tabContent}>Fuel Management </div>
            </Tab>
            <span className={styles.tabDivider}>|</span>
            <Tab className={`${styles.tab} ${selectedTab === 3 ? styles.selected : ""}`}>
              <div className={styles.tabContent}>Driver Directory </div>
            </Tab>
            <span className={styles.tabDivider}>|</span>
            <Tab className={`${styles.tab} ${selectedTab === 4 ? styles.selected : ""}`}>
              <div className={styles.tabContent}>Customer Management </div>
            </Tab>
            <span className={styles.tabDivider}>|</span>
            <Tab className={`${styles.tab} ${selectedTab === 5 ? styles.selected : ""}`}>
              <div className={styles.tabContent}>Route Planning</div>
            </Tab>
          </TabList>
        </div>
        <TabPanel>
          <Organization />
        </TabPanel>
        <TabPanel>
          <Fleet />
        </TabPanel>
        <TabPanel>
          <Fuel />
        </TabPanel>
        <TabPanel>
          <Drivers />
        </TabPanel>
        <TabPanel>
          <CustomerManagement />
        </TabPanel>
        <TabPanel>
          <RoutePlanning />
        </TabPanel>
      </Tabs>
    </div>
  );
};

export default withAuth(CostSettings);
