import { useState, useEffect } from "react";
import { FaSlidersH } from "react-icons/fa";
import { AiOutlineClose } from "react-icons/ai";
import { HiOutlineSortAscending, HiOutlineSortDescending } from "react-icons/hi";
import { retrievePriceDates } from "../helpers/priceFileHelper";
import styles from "./ConfigureButtonDropdown.module.css";

const ConfigureButtonDropdown = ({ configuration, setConfiguration, handleUpdateRouteAttributes, selectedRows, routeData }) => {
  const [dropdownIsOpen, setDropdownIsOpen] = useState(false);
  const [fileNames, setFileNames] = useState([]);
  const [filteredFileNames, setFilteredFileNames] = useState([]);
  const [formValues, setFormValues] = useState({
    minReserve: "",
    endReserve: "",
    maxStops: "",
    priceDate: "",
    radius: 200000,
  });
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({ ...prevValues, [name]: value }));
  };

  const parseFormValues = () => {
    return {
      minReserve: parseFloat(formValues.minReserve),
      endReserve: parseFloat(formValues.endReserve),
      maxStops: parseFloat(formValues.maxStops),
      priceDate: formValues.priceDate,
      radius: parseFloat(formValues.radius),
    };
  };

  // Local state for filtering
  const [region, setRegion] = useState("US");
  const [searchTerm, setSearchTerm] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");

  const isDateWithinRange = (fileName, dateToSearch) => {
    const dateParts = fileName.match(/\d{4}-\d{2}-\d{2}/g);
    if (dateParts.length === 1) {
      return dateParts[0].includes(dateToSearch);
    }
    if (dateParts.length === 2) {
      const [startDate, endDate] = dateParts;
      return new Date(startDate) <= new Date(dateToSearch) && new Date(dateToSearch) <= new Date(endDate);
    }
    return false;
  };

  const fetchFileNames = async () => {
    try {
      const response = await retrievePriceDates();
      if (response.status === 200) {
        setFileNames(response.data.availableDates);
      } else {
        console.error(`Error fetching file names: ${response.message}`);
      }
    } catch (error) {
      console.error(`Error fetching file names: ${error.message}`);
    }
  };

  useEffect(() => {
    fetchFileNames();
  }, []);

  useEffect(() => {
    let filtered = fileNames;
    filtered = filtered.filter((fileName) => (region === "US" ? fileName.startsWith("US") : fileName.startsWith("CA")));

    if (searchTerm) {
      filtered = filtered.filter((fileName) => {
        if (fileName.includes("to")) {
          return isDateWithinRange(fileName, searchTerm);
        }
        return fileName.includes(searchTerm);
      });
    }

    filtered.sort((a, b) => {
      const aDate = a.match(/\d{4}-\d{2}-\d{2}/)[0];
      const bDate = b.match(/\d{4}-\d{2}-\d{2}/)[0];
      return sortOrder === "asc" ? new Date(aDate) - new Date(bDate) : new Date(bDate) - new Date(aDate);
    });

    setFilteredFileNames(filtered);
  }, [fileNames, searchTerm, region, sortOrder]);

  return (
    <div className={styles.configContainer}>
      <button className={styles.configButton} onClick={() => setDropdownIsOpen(!dropdownIsOpen)}>
        <FaSlidersH />
      </button>
      {dropdownIsOpen && (
        <div className={styles.dropdownContent}>
          <div className={styles.header}>
            <h2>Configure Routes</h2>
            <AiOutlineClose className={styles.closeIcon} size="25px" onClick={() => setDropdownIsOpen(false)} />
          </div>
          <div className={styles.filterDateContainer}>
            <div className={styles.searchContainer}>
              <input type="text" placeholder="Search by date (e.g., 2023-03-30)" value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
            </div>
            <div className={styles.filterContainer}>
              <label>
                <input type="radio" checked={region === "US"} onChange={() => setRegion("US")} /> US
              </label>
              <label>
                <input type="radio" checked={region === "CA"} onChange={() => setRegion("CA")} /> CA
              </label>
            </div>
            <div className={styles.sortContainer}>
              <button onClick={() => setSortOrder("asc")} className={sortOrder === "asc" ? styles.active : ""}>
                <HiOutlineSortAscending color="black" size={"25px"} />
              </button>
              <button onClick={() => setSortOrder("desc")} className={sortOrder === "desc" ? styles.active : ""}>
                <HiOutlineSortDescending color="black" size={"25px"} />
              </button>
            </div>
          </div>
          <div className={styles.fileListContainer}>
            <select name="priceDate" className={styles.selectDateDropdown} value={formValues.priceDate} onChange={handleInputChange}>
              <option value="">Select a date</option>
              {filteredFileNames.map((fileName) => (
                <option key={fileName} value={fileName}>
                  {fileName}
                </option>
              ))}
            </select>
            <select className={styles.selectPriceColDropdown} value={configuration.priceCol} onChange={(e) => setConfiguration({ ...configuration, priceCol: e.target.value })}>
              <option value="Retail Price">Retail Price</option>
              <option value="Your Price">Your Price</option>
            </select>
          </div>{" "}
          <div className={styles.radiusInputGroup}>
            <label htmlFor="radius-slider">Radius: {formValues.radius} meters</label>
            <input id="radius-slider" type="range" min="0" max="500000" step="1000" value={formValues.radius} onChange={(e) => setFormValues({ ...formValues, radius: Number(e.target.value) })} className={styles.radiusSlider} />
          </div>
          <div className={styles["dropdown-menu"]}>
            <div className={styles["form-group"]}>
              <label>Min Reserve</label>
              <input name="minReserve" value={formValues.minReserve} onChange={handleInputChange} placeholder="Enter Min Reserve" />
            </div>
            <div className={styles["form-group"]}>
              <label>End Reserve</label>
              <input name="endReserve" value={formValues.endReserve} onChange={handleInputChange} placeholder="Enter End Reserve" />
            </div>
            <div className={styles["form-group"]}>
              <label>Stops</label>
              <input name="maxStops" value={formValues.maxStops} onChange={handleInputChange} placeholder="Enter Stops" />
            </div>
            <div className={styles["action-buttons-group"]}>
              <button className={styles["apply-button"]} onClick={() => handleUpdateRouteAttributes(routeData, parseFormValues())}>
                Apply All
              </button>
              <button className={styles["apply-button"]} onClick={() => handleUpdateRouteAttributes(selectedRows, parseFormValues())} disabled={selectedRows.length === 0}>
                Apply Selected
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ConfigureButtonDropdown;
