import axios from "axios";
import { serverConfig } from "./serverConfig";

// Create a customer
export const createCustomer = async (requestData) => {
  const token = localStorage.getItem("jwtToken");

  const options = {
    method: "POST",
    url: `${serverConfig.serverAddress}/customer/create`,
    data: requestData,
    headers: {
      "Content-Type": "application/json",
      "api-key": serverConfig.apiKey,
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const response = await axios(options);
    return {
      status: response.status,
      message: response.data.message || response.statusText,
      data: response.data,
    };
  } catch (error) {
    return {
      status: error.response?.status || 500,
      message: error.response?.data?.message || error.response?.statusText || "Failed to create customer",
    };
  }
};

// Edit a customer
export const editCustomer = async (customerId, requestData) => {
  const token = localStorage.getItem("jwtToken");

  const options = {
    method: "PUT",
    url: `${serverConfig.serverAddress}/customer/${customerId}/edit`,
    data: requestData,
    headers: {
      "Content-Type": "application/json",
      "api-key": serverConfig.apiKey,
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const response = await axios(options);
    return {
      status: response.status,
      message: response.data.message || response.statusText,
      data: response.data,
    };
  } catch (error) {
    return {
      status: error.response?.status || 500,
      message: error.response?.data?.message || error.response?.statusText || "Failed to edit customer",
    };
  }
};

// Delete a customer
export const deleteCustomer = async (customerId) => {
  const token = localStorage.getItem("jwtToken");

  const options = {
    method: "DELETE",
    url: `${serverConfig.serverAddress}/customer/${customerId}/delete`,
    headers: {
      "api-key": serverConfig.apiKey,
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const response = await axios(options);
    return {
      status: response.status,
      message: response.data.message || response.statusText,
      data: response.data,
    };
  } catch (error) {
    return {
      status: error.response?.status || 500,
      message: error.response?.data?.message || error.response?.statusText || "Failed to delete customer",
    };
  }
};

// Get all customers
export const getAllCustomers = async () => {
  const token = localStorage.getItem("jwtToken");

  const options = {
    method: "GET",
    url: `${serverConfig.serverAddress}/customer/get`,
    headers: {
      "api-key": serverConfig.apiKey,
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const response = await axios(options);
    return {
      status: response.status,
      message: response.data.message || response.statusText,
      data: response.data,
    };
  } catch (error) {
    return {
      status: error.response?.status || 500,
      message: error.response?.data?.message || error.response?.statusText || "Failed to fetch customers",
    };
  }
};
