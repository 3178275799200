const isLocal = window.location.hostname === "localhost";
const isTest = false;

//const apiUrl = isLocal || isTest ? "http://localhost:3003" : window.location.hostname.includes("vrtta.green") ? "https://gaddi-py-api.vrtta.green" : "Unauthorzied Domain";

const apiUrl = "https://gaddi-py-api.vrtta.green";
const apiKey = "9c44d472-420f-4c3b-8ccb-54c5654cb53e";
const serverConfig = {
  serverAddress: apiUrl,
  apiKey: apiKey,
};

export { serverConfig };
