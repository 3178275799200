import styles from "./RoutesMatrix.module.css";

const RoutesMatrix = ({ routeData }) => {
  console.log(routeData);

  return (
    <div className={styles.routesMatrix}>
      {routeData && routeData.total_cost ? (
        <div className={`${styles.matrixCell}`}>
          <div className={`${styles.cellContent}  ${styles.selected}`}>
            <div className={styles.containerText}>
              <div>
                {routeData.total_trip_distance} miles ({routeData.total_gallons} gal)
              </div>
              <div>Cost: {routeData.total_cost} CAD</div>
              <div>IFTA: {routeData.ifta_cost} CAD</div>
              <div>TTGHG: {routeData.TTGHG && routeData.TTGHG.toFixed(3)}</div>
            </div>
          </div>
        </div>
      ) : (
        <div className={styles.matrixCell}>
          <div className={styles.cellErrorContent}>
            <div className={styles.containerErrorText}>No Routes Available</div>
          </div>
        </div>
      )}
    </div>
  );
};

export default RoutesMatrix;
