import axios from "axios";
import { serverConfig } from "./serverConfig";

// Create a driver
export const createDriver = async (requestData) => {
  const token = localStorage.getItem("jwtToken");

  const options = {
    method: "POST",
    url: `${serverConfig.serverAddress}/driver/create`,
    data: requestData,
    headers: {
      "Content-Type": "application/json",
      "api-key": serverConfig.apiKey,
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const response = await axios(options);
    return {
      status: response.status,
      message: response.data.message || response.statusText,
      data: response.data,
    };
  } catch (error) {
    return {
      status: error.response?.status || 500,
      message: error.response?.data?.message || error.response?.statusText || "Failed to create driver",
    };
  }
};

// Edit a driver
export const editDriver = async (driverId, requestData) => {
  const token = localStorage.getItem("jwtToken");

  const options = {
    method: "PUT",
    url: `${serverConfig.serverAddress}/driver/${driverId}/edit`,
    data: requestData,
    headers: {
      "Content-Type": "application/json",
      "api-key": serverConfig.apiKey,
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const response = await axios(options);
    return {
      status: response.status,
      message: response.data.message || response.statusText,
      data: response.data,
    };
  } catch (error) {
    return {
      status: error.response?.status || 500,
      message: error.response?.data?.message || error.response?.statusText || "Failed to edit driver",
    };
  }
};

// Delete a driver
export const deleteDriver = async (driverId) => {
  const token = localStorage.getItem("jwtToken");

  const options = {
    method: "DELETE",
    url: `${serverConfig.serverAddress}/driver/${driverId}/delete`,
    headers: {
      "api-key": serverConfig.apiKey,
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const response = await axios(options);
    return {
      status: response.status,
      message: response.data.message || response.statusText,
      data: response.data,
    };
  } catch (error) {
    return {
      status: error.response?.status || 500,
      message: error.response?.data?.message || error.response?.statusText || "Failed to delete driver",
    };
  }
};

// Get all drivers
export const getAllDrivers = async () => {
  const token = localStorage.getItem("jwtToken");

  const options = {
    method: "GET",
    url: `${serverConfig.serverAddress}/driver/get`,
    headers: {
      "api-key": serverConfig.apiKey,
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const response = await axios(options);
    return {
      status: response.status,
      message: response.data.message || response.statusText,
      data: response.data,
    };
  } catch (error) {
    return {
      status: error.response?.status || 500,
      message: error.response?.data?.message || error.response?.statusText || "Failed to fetch drivers",
    };
  }
};
