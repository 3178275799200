import React, { useState, useEffect } from "react";
import styles from "./GridActions.module.css";
import { getAllCorridors } from "../helpers/corridorHelper";
import { sendSms } from "../helpers/messageHelper";
import { formatDateTime } from "../../utils/costUtils/formatDateTime";
import { FaExclamationTriangle } from "react-icons/fa";
import { FaSave } from "react-icons/fa";

const GridActions = ({ index, routeData, selectedRows, handleCalculateSelection, handleApproveRoute, selectedRoute, onExport, handleUpdateRouteAttributes, handleCustomGoogleLinkUpdate, handleDuplicateRoutes, unassignRoute }) => {
  const [isCalculateDisabled, setIsCalculateDisabled] = useState(true);
  const [isAssignDisabled, setIsAssignDisabled] = useState(true);
  const [showCustomRoute, setShowCustomRoute] = useState(false);

  const [googleLink, setGoogleLink] = useState(selectedRoute?.googleLink || "");
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);

  const [corridors, setCorridors] = useState([]);
  const [selectedCorridor, setSelectedCorridor] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState(selectedRoute?.driverMobile || "");
  const [message, setMessage] = useState("");

  useEffect(() => {
    const fetchCorridors = async () => {
      const response = await getAllCorridors();
      if (response.status === 200) {
        setCorridors(response.data.corridors);
      }
    };
    fetchCorridors();
  }, []);

  useEffect(() => {
    if (selectedRoute) {
      setGoogleLink(selectedRoute.googleLink);
      const tripDetails = `Here is your next trip from ${selectedRoute.startAddress} to ${selectedRoute.endAddress} starting on ${formatDateTime(selectedRoute.dispatchDate)}. \n`;
      const routeLink = `https://trucking.vrtta.green/cost/driver/route/${selectedRoute.routeId}`;
      setMessage(`${tripDetails} Check your route details here: ${routeLink}`);
    }
  }, [selectedRoute]);

  useEffect(() => {
    if (selectedCorridor) {
      const corridor = corridors.find((corridor) => corridor.name === selectedCorridor);
      if (corridor) {
        setGoogleLink(corridor.link);
        setIsSaveDisabled(false);
      }
    }
  }, [selectedCorridor, corridors]);

  const handleGoogleLinkChange = (newLink) => {
    if (selectedRoute) {
      const updatedRoute = {
        ...selectedRoute,
        googleLink: newLink,
      };
      setGoogleLink(newLink);
      setIsSaveDisabled(false);
    }
  };

  const handleSaveGoogleLink = () => {
    if (selectedRoute) {
      handleCustomGoogleLinkUpdate({
        ...selectedRoute,
        googleLink,
      });
      setIsSaveDisabled(true);
    }
  };

  const handlePhoneNumberChange = (e) => {
    setPhoneNumber(e.target.value);
  };

  const handleSendSMS = async () => {
    if (!phoneNumber || !message) {
      return;
    }

    const requestData = {
      phone_number: phoneNumber,
      message,
    };

    const response = await sendSms(requestData);

    if (response.status === 200) {
      alert("SMS sent successfully!");
    } else {
      alert(`Failed to send SMS: ${response.message}`);
    }
  };

  useEffect(() => {
    if (selectedRows.length === 0) {
      setIsCalculateDisabled(true);
      setIsAssignDisabled(true);
    } else {
      setIsCalculateDisabled(!(index === 0 || index === 1));
      setIsAssignDisabled(!(index === 1));
    }
  }, [selectedRows, index]);

  return (
    <div className={styles["action-buttons"]}>
      <button className={styles["export-button"]} onClick={onExport}>
        Export
      </button>

      {index === 0 && (
        <>
          <button className={styles["calculate-button"]} onClick={handleCalculateSelection} disabled={isCalculateDisabled}>
            Calculate
          </button>
          <button className={styles["duplicate-button"]} onClick={handleDuplicateRoutes} disabled={isCalculateDisabled}>
            Duplicate
          </button>

          {selectedRoute && selectedRoute.error && (
            <div className={styles.warningBox}>
              <FaExclamationTriangle className={styles.icon} />
              Route cannot be calculated with current configuration
            </div>
          )}
          {selectedRoute && (
            <div className={styles["user-route"]}>
              <input type="checkbox" checked={showCustomRoute} onChange={() => setShowCustomRoute(!showCustomRoute)} className={styles.customRouteCheckbox} />
              <label className={styles.customRouteLabel}>Custom Route</label>

              {showCustomRoute && (
                <div className={styles["google-link-container"]}>
                  <select
                    onChange={(e) => {
                      const selectedCorridorName = e.target.value;
                      setSelectedCorridor(selectedCorridorName);
                    }}
                    value={selectedCorridor || ""}
                  >
                    <option value="">Select Corridor</option>
                    {corridors.map((corridor) => (
                      <option key={corridor.name} value={corridor.name}>
                        {corridor.name}
                      </option>
                    ))}
                  </select>
                  <input
                    type="text"
                    value={googleLink}
                    onChange={(e) => {
                      const newGoogleLink = e.target.value;
                      setGoogleLink(newGoogleLink);
                      setIsSaveDisabled(false);
                    }}
                    placeholder="Enter Custom Google Link"
                  />
                  <button className={styles["save-button"]} onClick={handleSaveGoogleLink} disabled={isSaveDisabled}>
                    <FaSave />
                  </button>
                </div>
              )}
            </div>
          )}
        </>
      )}

      {index === 1 && (
        <>
          <button className={styles["revert-button"]} onClick={() => unassignRoute(selectedRoute.routeId)} disabled={isAssignDisabled}>
            Cancel
          </button>

          <button className={styles["assign-button"]} onClick={() => handleApproveRoute(selectedRoute.routeId, selectedRoute.assignedRoute)} disabled={isAssignDisabled}>
            Approve
          </button>
        </>
      )}

      {index === 2 && selectedRoute && (
        <div className={styles["sms-section"]}>
          <input type="tel" value={phoneNumber} onChange={handlePhoneNumberChange} placeholder="Enter phone number" />
          <button className={styles["send-sms-button"]} onClick={handleSendSMS}>
            Send Link
          </button>
        </div>
      )}
    </div>
  );
};

export default GridActions;
