import React, { useState, useEffect } from "react";
import { FaPlus, FaEdit, FaTrash, FaTimes } from "react-icons/fa";
import { getAllCustomers, createCustomer, editCustomer, deleteCustomer } from "../helpers/customerHelper";
import ConfirmModal from "../shared/ConfirmModal";
import styles from "./CustomerManagement.module.css";

const CustomerManagement = () => {
  const [customers, setCustomers] = useState([]);
  const [editingCustomerId, setEditingCustomerId] = useState(null);
  const [newCustomer, setNewCustomer] = useState({ name: "", email: "", mobile: "", addresses: [""] });
  const [editingCustomerData, setEditingCustomerData] = useState({});
  const [showCustomerForm, setShowCustomerForm] = useState(false);

  useEffect(() => {
    fetchCustomers();
  }, []);

  const fetchCustomers = async () => {
    try {
      const response = await getAllCustomers();
      if (response.status === 200) {
        const processedCustomers = response.data.customers.map((customer) => ({
          ...customer,
          addresses: Array.isArray(customer.addresses) ? customer.addresses : JSON.parse(customer.addresses || "[]"),
        }));
        setCustomers(processedCustomers);
      } else {
        alert("Failed to fetch customers");
      }
    } catch (error) {
      console.error("Error fetching customers:", error);
      alert("An error occurred while fetching customers.");
    }
  };

  const handleCreate = async () => {
    const response = await createCustomer(newCustomer);
    if (response.status === 200) {
      setNewCustomer({ name: "", email: "", mobile: "", addresses: [""] });
      setShowCustomerForm(false);
      fetchCustomers();
    } else {
      alert("Failed to create customer");
    }
  };

  const handleEdit = (customer) => {
    console.log(customer);
    setEditingCustomerId(customer.customerId);
    setEditingCustomerData({
      name: customer.customerName,
      email: customer.customerEmail,
      mobile: customer.customerMobile,
      addresses: customer.addresses,
    });
  };

  const handleSave = async () => {
    const response = await editCustomer(editingCustomerId, editingCustomerData);
    console.log(editingCustomerData);
    if (response.status === 200) {
      setEditingCustomerId(null);
      fetchCustomers();
    } else {
      alert("Failed to save customer");
    }
  };

  const handleDelete = async (customerId) => {
    const response = await deleteCustomer(customerId);
    if (response.status === 200) {
      fetchCustomers();
    } else {
      alert("Failed to delete customer");
    }
  };

  const handleAddAddress = (isEditing) => {
    if (isEditing) {
      setEditingCustomerData((prev) => ({
        ...prev,
        addresses: [...prev.addresses, ""],
      }));
    } else {
      setNewCustomer((prev) => ({
        ...prev,
        addresses: [...prev.addresses, ""],
      }));
    }
  };

  const handleRemoveAddress = (index, isEditing) => {
    if (isEditing) {
      setEditingCustomerData((prev) => ({
        ...prev,
        addresses: prev.addresses.filter((_, i) => i !== index),
      }));
    } else {
      setNewCustomer((prev) => ({
        ...prev,
        addresses: prev.addresses.filter((_, i) => i !== index),
      }));
    }
  };

  const handleAddressChange = (index, value, isEditing) => {
    if (isEditing) {
      setEditingCustomerData((prev) => ({
        ...prev,
        addresses: prev.addresses.map((addr, i) => (i === index ? value : addr)),
      }));
    } else {
      setNewCustomer((prev) => ({
        ...prev,
        addresses: prev.addresses.map((addr, i) => (i === index ? value : addr)),
      }));
    }
  };

  return (
    <div className={styles.customerManagementContainer}>
      <div className={styles.title}>
        Customer Management
        <button className={styles.addButton} onClick={() => setShowCustomerForm(!showCustomerForm)}>
          <FaPlus style={{ marginRight: "5px" }} /> Add New Customer
        </button>
      </div>
      {showCustomerForm && (
        <div className={styles.section}>
          <div className={styles.field}>
            <label className={styles.label}>Customer Name</label>
            <input type="text" value={newCustomer.name} className={styles.input} onChange={(e) => setNewCustomer({ ...newCustomer, name: e.target.value })} />
          </div>
          <div className={styles.field}>
            <label className={styles.label}>Mobile</label>
            <input type="text" value={newCustomer.mobile} className={styles.input} onChange={(e) => setNewCustomer({ ...newCustomer, mobile: e.target.value })} />
          </div>
          <div className={styles.field}>
            <label className={styles.label}>Email</label>
            <input type="email" value={newCustomer.email} className={styles.input} onChange={(e) => setNewCustomer({ ...newCustomer, email: e.target.value })} />
          </div>
          <div className={styles.addresses}>
            <label className={styles.label}>Addresses</label>
            {newCustomer.addresses.map((address, index) => (
              <div key={index} className={styles.addressField}>
                <input type="text" value={address} className={styles.input} onChange={(e) => handleAddressChange(index, e.target.value, false)} />
                <FaTimes size="18px" className={styles.removeIcon} onClick={() => handleRemoveAddress(index, false)} />
              </div>
            ))}
            <button className={styles.addButton} onClick={() => handleAddAddress(false)}>
              + Add New Address
            </button>
          </div>
          <div className={styles.actions}>
            <button className={styles.button} onClick={handleCreate}>
              Create Customer
            </button>
          </div>
        </div>
      )}

      <div className={styles.divider} />
      <div className={styles.customerDetailsHeader}>
        <div>Customer Name</div>
        <div>Mobile</div>
        <div>Email</div>
        <div>Addresses</div>
      </div>
      <div className={styles.detailsContainer}>
        {customers.map((customer) => (
          <div key={customer.customerId} className={styles.customerItem}>
            {editingCustomerId === customer.customerId ? (
              <>
                <div>
                  <input type="text" value={editingCustomerData.name} onChange={(e) => setEditingCustomerData({ ...editingCustomerData, customerName: e.target.value })} />
                </div>
                <div>
                  <input type="text" value={editingCustomerData.mobile} onChange={(e) => setEditingCustomerData({ ...editingCustomerData, customerMobile: e.target.value })} />
                </div>
                <div>
                  <input type="email" value={editingCustomerData.email} onChange={(e) => setEditingCustomerData({ ...editingCustomerData, customerEmail: e.target.value })} />
                </div>
                <div className={styles.addresses}>
                  {editingCustomerData.addresses.map((address, index) => (
                    <div key={index} className={styles.addressField}>
                      <input type="text" value={address} className={styles.input} onChange={(e) => handleAddressChange(index, e.target.value, true)} />
                      <FaTimes className={styles.removeIcon} onClick={() => handleRemoveAddress(index, true)} />
                    </div>
                  ))}
                  <button className={styles.addButton} onClick={() => handleAddAddress(true)}>
                    + Add New Address
                  </button>
                </div>
                <div className={styles.actions}>
                  <button className={styles.button} onClick={handleSave}>
                    Save
                  </button>
                  <button className={styles.button} onClick={() => setEditingCustomerId(null)}>
                    Cancel
                  </button>
                </div>
              </>
            ) : (
              <>
                <div className={styles.customerDetails}>
                  <div>{customer.customerName || "N/A"}</div>
                  <div>{customer.customerMobile || "N/A"}</div>
                  <div>{customer.customerEmail || "N/A"}</div>
                  <div>{customer.addresses?.join(", ") || "N/A"}</div>
                </div>
                <div className={styles.actions}>
                  <button onClick={() => handleEdit(customer)} className={styles.iconButton}>
                    <FaEdit color="#d15533" size="20px" />
                  </button>
                  <ConfirmModal title="Delete" text="Are you sure you want to delete this customer?" onConfirm={() => handleDelete(customer.customerId)} iconButton={() => <FaTrash />} />
                </div>
              </>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default CustomerManagement;
