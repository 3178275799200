import React, { useState, useEffect } from "react";
import Grid from "../../environment-page-components/Grid";
import styles from "./AssetsTable.module.css";
import config from "../../config";
import { Icon } from "@iconify/react";
import Papa from "papaparse";

const AssetsTable = ({ selectedRowData, selectedLanguage, refreshData, allAssets, loadingData }) => {
  const [isOpen, setIsOpen] = useState(true);
  const [isHovered, setIsHovered] = useState(false);
  const [gridApi, setGridApi] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [averageGhgEmissions, setAverageGhgEmissions] = useState(0);
  const [selectedRow, setSelectedRow] = useState();
  const [selectedAssetNumbers, setSelectedAssetNumbers] = useState([]);
  const [initialSelectedAssets, setInitialSelectedAssets] = useState([]);
  const [isSaveButtonEnabled, setIsSaveButtonEnabled] = useState(false);
  const [assets, setAssets] = useState(allAssets);

  const [apiKey, setApiKey] = useState(localStorage.getItem("apiKey") || "samsara_api_v4yzCt6mQpGraWC9sfXgu4Fs3dTi2M");
  const [startDate, setStartDate] = useState(localStorage.getItem("startDate") || "2023-01-01");
  const [endDate, setEndDate] = useState(localStorage.getItem("endDate") || "2023-12-31");
  const system = "metric";

  useEffect(() => {
    localStorage.setItem("apiKey", apiKey);
  }, [apiKey]);

  useEffect(() => {
    localStorage.setItem("startDate", startDate);
  }, [startDate]);

  useEffect(() => {
    localStorage.setItem("endDate", endDate);
  }, [endDate]);

  useEffect(() => {
    setAssets(allAssets);
  }, [allAssets]);

  const toggleContainer = () => setIsOpen(!isOpen);

  useEffect(() => {
    if (selectedRowData.length > 0) {
      const initialAssets = Array.isArray(selectedRowData[0].asset_numbers) ? selectedRowData[0].asset_numbers : JSON.parse(selectedRowData[0].asset_numbers);
      setSelectedRow(selectedRowData[0]);
      setSelectedAssetNumbers(initialAssets);
      setInitialSelectedAssets([...initialAssets]);
    }
  }, [selectedRowData]);

  useEffect(() => {
    const filteredData = assets.filter((asset) => selectedAssetNumbers.includes(asset.assetNo));
    setTableData(filteredData);
  }, [selectedAssetNumbers, assets]);

  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  const handleAssetSelection = (assetNo) => {
    setSelectedAssetNumbers((prevSelected) => {
      const newSelected = prevSelected.includes(assetNo) ? prevSelected.filter((item) => item !== assetNo) : [...prevSelected, assetNo];
      return newSelected;
    });
  };

  useEffect(() => {
    if (JSON.stringify(selectedAssetNumbers) !== JSON.stringify(initialSelectedAssets)) {
      setIsSaveButtonEnabled(true);
    } else {
      setIsSaveButtonEnabled(false);
    }
  }, [selectedAssetNumbers, initialSelectedAssets]);

  useEffect(() => {
    const selectedAssets = tableData.filter((asset) => selectedAssetNumbers.includes(asset.assetNo));
    const totalEmissions = selectedAssets.reduce((total, asset) => {
      return total + (parseFloat(asset.ghgEmissions) || 0);
    }, 0);

    const averageEmissions = selectedAssets.length > 0 ? totalEmissions / selectedAssets.length : 0;
    setAverageGhgEmissions(averageEmissions);
  }, [selectedAssetNumbers, tableData]);

  const saveTable = async () => {
    const selectedAssetsData = tableData.filter((row) => selectedAssetNumbers.includes(row.assetNo));

    const totalFuelConsumed = selectedAssetsData.reduce((total, asset) => {
      const fuel = parseFloat(asset.fuelConsumed) || 0;
      return total + fuel;
    }, 0);

    const totalDistanceTravelled = selectedAssetsData.reduce((total, asset) => {
      const distance = parseFloat(asset.distanceTravelled) || 0;
      return total + distance;
    }, 0);

    if (selectedAssetsData.length === 0) {
      console.error("No assets selected");
      return;
    }

    const requestData = {
      fleet: selectedRow.fleet,
      vehicle_type: selectedRow.vehicle_type,
      trailer_type: selectedRow.trailer_type,
      location: selectedRow.location,
      Year: selectedRow.Year,
      fuel_consumed: totalFuelConsumed,
      distance_travelled: totalDistanceTravelled,
      gwp_total: totalFuelConsumed * 2.62,
      distance_per_gwp: totalDistanceTravelled / (totalFuelConsumed * 2.62) || 0,
      asset_numbers: JSON.stringify(selectedAssetNumbers),
      fleet_assets: JSON.stringify(assets),
      user_id: selectedRow.user_id,
    };

    try {
      const response = await fetch(`${config.apiUrl}/update_data`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          table_name: "fleets",
          data: requestData,
          condition: `id = '${selectedRow.id}'`,
        }),
      });

      if (!response.ok) {
        console.error("Failed to update fleet metrics");
      }
      window.location.reload();
    } catch (error) {
      console.error("Error saving fleet metrics:", error);
    }
  };

  const assetsGridProps = {
    columnDefs: [
      { headerName: "Asset No", field: "assetNo", editable: false, minWidth: 100, checkboxSelection: true, headerCheckboxSelection: true },
      { headerName: "Fuel Economy (L/100km)", field: "fuelEconomy", editable: false, minWidth: 150 },
      {
        headerName: "Fuel Consumed (L)",
        field: "fuelConsumed",
        editable: false,
        minWidth: 150,
        valueFormatter: (params) => Math.round(params.value).toString(),
      },
      {
        headerName: "Distance Travelled (km)",
        field: "distanceTravelled",
        editable: false,
        minWidth: 160,
        valueFormatter: (params) => Math.round(params.value).toString(),
      },
      { headerName: "Idling %", field: "idlingPercent", editable: false, minWidth: 120 },
      { headerName: "GHG per km (kg CO2-eq/km)", field: "ghgEmissions", editable: false, minWidth: 200, cellStyle: { textAlign: "right" } },
    ],
    rowData: tableData,
    sideBar: {
      toolPanels: [
        {
          id: "columns",
          labelDefault: "Columns",
          labelKey: "columns",
          iconKey: "columns",
          toolPanel: "agColumnsToolPanel",
          toolPanelParams: {
            suppressRowGroups: true,
            suppressValues: true,
            suppressPivots: true,
            suppressPivotMode: true,
          },
        },
        {
          id: "filters",
          labelDefault: "Filters",
          labelKey: "filters",
          iconKey: "filter",
          toolPanel: "agFiltersToolPanel",
        },
      ],
      defaultToolPanel: "filters",
    },
    onGridReady: onGridReady,
  };

  const handleCSVUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      Papa.parse(file, {
        complete: (result) => {
          const parsedData = result.data.map((row) => ({
            assetNo: row["Asset"],
            fuelEconomy: row["Fuel economy (L/100km)"],
            fuelConsumed: row["Fuel consumption (gal)"],
            distanceTravelled: row["Distance (km)"],
            idlingPercent: row["Idle time/Engine time (%)"],
            ghgEmissions: row["GHG per km (kg CO2-eq/km)"],
            make: row["Make"],
            year: row["Year"],
          }));

          const filteredData = parsedData.map((row) => ({
            Vehicle: row.assetNo || "N/A",
            "Fuel economy (L/100km)": row.fuelEconomy || 0,
            "Fuel consumption (L)": row.fuelConsumed || 0,
            "Distance travelled (km)": row.distanceTravelled || 0,
            "Engine run time (hr)": row.engineRunTime || 0,
            "Engine idle time (hr)": row.engineIdleTime || 0,
            "Idling time (%)": row.idlingPercent || 0,
            "GHG per km (kg CO2-eq/km)": row.ghgEmissions || 0,
            "Energy used (MJ/km)": row.energyUsed || 0,
            "Total useful energy of ICE based on 40% engine efficiency (MJ/km)": row.usefulEnergyICE || 0,
            "Total electrical energy needed by the asset (kWh/km)": row.electricalEnergy || 0,
            Year: row.year || "N/A",
            Make: row.make || "N/A",
          }));
          setTableData(filteredData);
          setAssets(parsedData);
        },
        header: true,
      });
    }
  };

  const fetchAssetsData = async () => {
    try {
      const urlBase = "https://gaddi-js-api.vrtta.green";
      const response = await fetch(`${urlBase}/samsara-proxy?apiKey=${apiKey}&startDate=${startDate}&endDate=${endDate}&system=${system}`);

      if (!response.ok) throw new Error("Network response was not ok");

      const data = await response.json();

      if (!(Array.isArray(data[0]) && data[0].length > 0)) {
        throw new Error("No Data Found!");
      }

      const mappedData = data[0].map((item) => ({
        assetNo: item["Vehicle"],
        fuelEconomy: item["Fuel economy (L/100km)"],
        fuelConsumed: item["Fuel consumption (L)"],
        distanceTravelled: item["Distance travelled (km)"],
        engineRunTime: item["Engine run time (hr)"],
        idlingPercent: item["Idling time (%)"],
        ghgEmissions: item["GHG per km (kg CO2-eq/km)"],
        year: item["Year"],
      }));

      setTableData(data[0]);
      setAssets(mappedData);
    } catch (error) {
      console.error("Error fetching asset data:", error);
    }
  };

  return (
    <div className="process-table">
      <div className="container-header">
        <h2
          onClick={toggleContainer}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          style={{
            cursor: "pointer",
            color: isHovered ? "grey" : "black",
          }}
        >
          Assets
        </h2>{" "}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "10px",
            width: "100%",
            margin: "auto",
            justifyContent: "right",
          }}
        >
          <label
            style={{
              display: "flex",
              flexDirection: "column",
              fontSize: "14px",
              fontWeight: "bold",
              marginBottom: "10px",
              textAlign: "left",
            }}
          >
            Start Date:
            <input
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              style={{
                padding: "8px",
                marginTop: "5px",
                border: "1px solid #ccc",
                borderRadius: "4px",
                width: "150px",
              }}
            />
          </label>
          <label
            style={{
              display: "flex",
              flexDirection: "column",
              fontSize: "14px",
              fontWeight: "bold",
              marginBottom: "10px",
              textAlign: "left",
            }}
          >
            End Date:
            <input
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              style={{
                padding: "8px",
                marginTop: "5px",
                border: "1px solid #ccc",
                borderRadius: "4px",

                width: "150px",
              }}
            />
          </label>
          <button
            onClick={fetchAssetsData}
            style={{
              padding: "10px 20px",
              fontSize: "14px",
              fontWeight: "bold",
              backgroundColor: "#007BFF",
              color: "#fff",
              border: "none",
              borderRadius: "4px",
              cursor: "pointer",
              alignSelf: "center",
              marginTop: "10px",
            }}
          >
            Update
          </button>{" "}
          <div className="csv-upload-container" style={{ marginTop: "29px" }}>
            <label
              htmlFor="csv-upload"
              style={{
                padding: "10px 20px",
                fontSize: "14px",
                fontWeight: "bold",
                backgroundColor: "#007BFF",
                color: "#fff",
                border: "none",
                borderRadius: "4px",
                cursor: "pointer",
              }}
            >
              Upload CSV
            </label>
            <input id="csv-upload" type="file" accept=".csv" onChange={handleCSVUpload} style={{ display: "none" }} />
          </div>
        </div>
      </div>
      {loadingData ? (
        <div className={styles.loadingSpinner}>Loading...</div>
      ) : isOpen ? (
        <>
          <div className={styles.gridContainerEnv}>
            <div className="grid-container">
              <Grid {...assetsGridProps} customMinHeight={264} customMaxheight={264} />
              <div className="gwp-total-env">
                <span className="gwp-total-env-text">Average:</span>
                <span className="gwp-total-env-number">
                  {averageGhgEmissions.toFixed(2)} kg Co<sub>2</sub>e
                </span>
              </div>
            </div>
            <div className={styles.assetSelectionContainer}>
              <h3 className={styles.assetSelectionTitle}>Asset Selection</h3>
              <div className={styles.selectionButtons}>
                <button className={styles.selectButton} onClick={() => setSelectedAssetNumbers(assets.map((asset) => asset.assetNo))}>
                  Select All
                </button>
                <button className={styles.deselectButton} onClick={() => setSelectedAssetNumbers([])}>
                  Deselect All
                </button>
              </div>
              <div className={styles.availableAssets}>
                {assets.map((asset) => (
                  <div key={asset.assetNo} className={`${styles.assetItem} ${selectedAssetNumbers.includes(asset.assetNo) ? styles.assetItemSelected : ""}`} onClick={() => handleAssetSelection(asset.assetNo)}>
                    {asset.assetNo}
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="button-container-env">
            <div className="button-container">
              <div className="button-container-left"></div>
              <div className="button-container-right">
                <button onClick={saveTable} title="Save current data in the table to database" disabled={!isSaveButtonEnabled}>
                  <Icon icon="mdi:floppy-disk" style={{ fontSize: "16px" }} />
                </button>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
};

export default AssetsTable;
