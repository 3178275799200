import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from "recharts";

const GraphComponent = ({ minReserve, selectedRoute, tankCapacity, fuelEconomy, gasLevel }) => {
  if (!selectedRoute || !selectedRoute.gaddi_matrix) return null;

  const data = [];
  let totalDistance = 0;
  let currentGas = parseFloat(gasLevel);

  selectedRoute.gaddi_matrix.forEach((stop, index, arr) => {
    const stopPrice = parseFloat(stop[3]);
    const gasToAdd = parseFloat(stop[4]);
    const distance = parseFloat(stop[6]);

    if (isNaN(distance) || isNaN(stopPrice) || isNaN(gasToAdd)) {
      console.error("Invalid data in matrix", stop);
      return;
    }

    if (index > 0) {
      totalDistance += distance;

      const gasConsumption = distance / parseFloat(fuelEconomy);
      currentGas = Math.max(currentGas - gasConsumption, 0);
    }

    const costPerMile = (parseFloat(stopPrice) / parseFloat(fuelEconomy)).toFixed(2);

    if (parseFloat(costPerMile) === 0) {
      if (index === 0 && arr.length > 1) {
        // First point with no costPerMile, use next point's costPerMile
        const nextStop = arr[index + 1];
        const nextStopPrice = parseFloat(nextStop[3]);
        const nextCostPerMile = (nextStopPrice / parseFloat(fuelEconomy)).toFixed(2);
        data.push({
          distance: totalDistance.toFixed(0),
          currentGas: currentGas.toFixed(0),
          costPerMile: nextCostPerMile,
        });
      } else if (index === arr.length - 1 && data.length > 0) {
        // Last point with no costPerMile, use previous point's costPerMile
        const prevDataPoint = data[data.length - 1];
        data.push({
          distance: totalDistance.toFixed(0),
          currentGas: currentGas.toFixed(0),
          costPerMile: prevDataPoint.costPerMile,
        });
      } else {
        data.push({
          distance: totalDistance.toFixed(0),
          currentGas: currentGas.toFixed(0),
        });
      }
    } else {
      data.push({
        distance: totalDistance.toFixed(0),
        currentGas: currentGas.toFixed(0),
        costPerMile,
      });
    }

    const gasAdded = parseFloat(gasToAdd);
    if (gasAdded > 0) {
      currentGas = Math.min(currentGas + gasAdded, tankCapacity);
      const newCostPerMile = (parseFloat(stopPrice) / parseFloat(fuelEconomy)).toFixed(2);

      if (parseFloat(newCostPerMile) === 0) {
        if (index === 0 && arr.length > 1) {
          const nextStop = arr[index + 1];
          const nextStopPrice = parseFloat(nextStop[3]);
          const nextCostPerMile = (nextStopPrice / parseFloat(fuelEconomy)).toFixed(2);
          data.push({
            distance: totalDistance.toFixed(0),
            currentGas: currentGas.toFixed(0),
            costPerMile: nextCostPerMile,
          });
        } else if (index === arr.length - 1 && data.length > 0) {
          const prevDataPoint = data[data.length - 1];
          data.push({
            distance: totalDistance.toFixed(0),
            currentGas: currentGas.toFixed(0),
            costPerMile: prevDataPoint.costPerMile,
          });
        } else {
          data.push({
            distance: totalDistance.toFixed(0),
            currentGas: currentGas.toFixed(0),
          });
        }
      } else {
        data.push({
          distance: totalDistance.toFixed(0),
          currentGas: currentGas.toFixed(0),
          costPerMile: newCostPerMile,
        });
      }
    }
  });

  const maxDistance = Math.max(...data.map((d) => parseFloat(d.distance)), 0);
  const validCosts = data.map((d) => parseFloat(d.costPerMile)).filter((cost) => !isNaN(cost) && cost > 0);

  const maxCostPerMile = Math.max(...validCosts, 0.5);
  const maxGasRounded = Math.ceil(tankCapacity / 50) * 50;
  const gasTicks = Array.from({ length: maxGasRounded / 50 + 1 }, (_, i) => i * 50);

  const maxCostRounded = Math.ceil(maxCostPerMile / 0.1) * 0.1;
  const costTicks = Array.from({ length: Math.ceil(maxCostRounded / 0.1) + 1 }, (_, i) => i * 0.1);

  const distanceTicks = Array.from({ length: Math.ceil(maxDistance / 250) + 1 }, (_, i) => i * 250).filter((tick) => tick <= maxDistance);

  return (
    <div style={{ width: "100%", height: 300, marginTop: "5px" }}>
      <ResponsiveContainer width="100%" height="100%">
        <LineChart data={data}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="distance" label={{ value: "Distance (miles)", position: "insideBottom", offset: -1 }} type="number" domain={[0, maxDistance]} ticks={distanceTicks} tickFormatter={(value) => `${value.toFixed(0)}`} />
          <YAxis
            yAxisId="left"
            label={{
              value: "Current Gas (gal)",
              angle: -90,
              position: "insideLeft",
              dy: 30,
              fill: "#0d6efd",
            }}
            domain={[0, maxGasRounded]}
            ticks={gasTicks}
            tickFormatter={(value) => `${value.toFixed(0)}`}
            stroke="#0d6efd"
          />
          <YAxis
            yAxisId="right"
            orientation="right"
            label={{
              value: "Cost Per Mile ($/mile)",
              angle: -90,
              position: "insideTopRight",
              fill: "#FF6347",
              offset: 8,
            }}
            domain={[0, maxCostRounded]}
            ticks={costTicks}
            tickFormatter={(value) => `${value.toFixed(2)}`}
            stroke="#FF6347"
          />
          <Tooltip />
          <Legend />
          <Line yAxisId="left" type="linear" dataKey="currentGas" stroke="#0d6efd" strokeWidth={3} name="Current Gas" />
          <Line yAxisId="right" type="monotone" dataKey="costPerMile" stroke="#FF6347" strokeWidth={3} name="Cost Per Mile" />
          <Line yAxisId="left" type="linear" dataKey={() => minReserve} stroke="#149029" strokeDasharray="5 5" strokeWidth={3} name="Minimum Reserve" />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default GraphComponent;
