import React, { useState, useEffect, useCallback } from "react";
import { AgGridReact } from "ag-grid-react";
import { AllCommunityModule, ModuleRegistry, provideGlobalGridOptions } from "ag-grid-community";
import { retrievePriceDates } from "../helpers/priceFileHelper";

import { formatDateTime } from "../../utils/costUtils/formatDateTime";

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";

import GridActions from "./GridActions";
import ViewRoutes from "./ViewRoutes";

ModuleRegistry.registerModules([AllCommunityModule]);
provideGlobalGridOptions({ theme: "legacy" });
const RouteGrid = ({ headerData, routeData, handleSelectedRoutes, handleCalculateSelection, handleApproveRoute, index, handleUpdateRouteAttributes, handleCustomGoogleLinkUpdate, handleDuplicateRoutes, unassignRoute }) => {
  const [columnDefs, setColumnDefs] = useState([]);
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [selectedRoute, setSelectedRoute] = useState(null);
  const [averages, setAverages] = useState({});
  const [priceDates, setPriceDates] = useState([]);

  useEffect(() => {
    const fetchFileNames = async () => {
      try {
        const response = await retrievePriceDates();
        if (response.status === 200) {
          setPriceDates(response.data.availableDates);
        } else {
          console.error(`Error fetching price dates: ${response.message}`);
        }
      } catch (error) {
        console.error(`Error fetching price dates: ${error.message}`);
      }
    };

    fetchFileNames();
  }, []);

  const tableHeight = index === 0 ? "calc(100vh - 260px)" : "calc(100vh - 400px)";

  const baseTheme = {};

  const myTheme =
    index === 1
      ? {
          ...baseTheme,
        }
      : index === 2
      ? {
          ...baseTheme,
        }
      : baseTheme;

  useEffect(() => {
    if (priceDates.length === 0 || routeData.length === 0) return;

    const updatedRouteData = routeData.map((route) => {
      if (!route.priceDate && priceDates.length > 0) {
        route.priceDate = priceDates[0];
      }
      return route;
    });

    const generateColumnDefs = () => {
      const headers = headerData.map((header, index) => {
        let columnDef = {
          headerName: header.headerText,
          field: header.label,
          sortable: true,
          filter: true,
          resizable: true,
          editable: true,
          suppressSizeToFit: false,
          hide: header.hidden || false,
          valueFormatter:
            header.label === "dispatchDate" || header.label === "deliveryDate"
              ? (params) => formatDateTime(params.value)
              : header.label === "TTGHG"
              ? (params) => {
                  if (params.value == null || isNaN(params.value)) return "N/A";
                  return parseFloat(params.value).toFixed(2);
                }
              : undefined,
          checkboxSelection: index === 0,
          cellStyle: (params) => {
            if (params.data.calculationStatus === "in-progress") {
              return {
                background: "repeating-linear-gradient(45deg, #d4fcd4, #d4fcd4 10px,#b3d1b3 10px, #b3d1b3 15px)",
              };
            }
            if (params.data.calculationStatus === "queued") {
              return { backgroundColor: "#e3e3e3" };
            }
            if (params.data.calculationStatus === "failed") {
              return { backgroundColor: "#f8a3a3", fontWeight: "bold" };
            }
            if (params.data.status === "trial") {
              return { backgroundColor: "#F5DEB3" };
            }

            return null;
          },
        };

        if (header.label === "priceDate") {
          columnDef = {
            ...columnDef,
            cellEditor: "agSelectCellEditor",
            cellEditorParams: {
              values: priceDates,
            },
            editable: true,

            valueSetter: (params) => {
              if (params.newValue) {
                params.data.priceDate = params.newValue;
              }
              return true;
            },
          };
        }

        if (header.label === "maxStops" || header.label === "minReserve" || header.label === "endReserve") {
          columnDef.editable = true;
        }

        if (header.label === "startAddress" || header.label === "endAddress" || header.label === "vehicleNo" || header.label === "driverName" || header.label === "invoiceNo" || header.label === "driverMobile") {
          columnDef.filter = "agTextColumnFilter";
          columnDef.filterParams = {
            defaultOption: "contains",
            options: ["contains", "equals"],
          };
        }

        if (header.label === "dispatchDate" || header.label === "deliveryDate") {
          columnDef.filter = "agDateColumnFilter";
          columnDef.minWidth = 150;
          columnDef.filterParams = {
            comparator: (filterLocalDateAtMidnight, cellValue) => {
              if (!cellValue) return -1;
              const cellDate = new Date(cellValue);
              if (cellDate < filterLocalDateAtMidnight) return -1;
              if (cellDate > filterLocalDateAtMidnight) return 1;
              return 0;
            },
          };
        }

        if (header.label === "loadWeight" || header.label === "tankCapacity" || header.label === "routeLength" || header.label === "totalGas" || header.label === "IFTA" || header.label === "TTGHG") {
          columnDef.filter = "agNumberColumnFilter";
          columnDef.filterParams = {
            defaultOption: "equals",
            options: ["equals", "lessThan", "greaterThan", "inRange"],
          };
        }

        if (header.label === "startAddress" || header.label === "endAddress" || header.label === "priceDate") {
          columnDef.minWidth = 100;
        } else {
          columnDef.flex = 1;
        }

        return columnDef;
      });

      setColumnDefs(headers);
    };

    generateColumnDefs();
  }, [headerData, priceDates, routeData]);

  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
    params.api.sizeColumnsToFit();
  };

  const onSelectionChanged = () => {
    const selectedRows = gridApi.getSelectedRows();

    if (selectedRows.length > 0) {
      setSelectedRoute(selectedRows[selectedRows.length - 1]);
    }
    handleSelectedRoutes(selectedRows);
  };

  const onSortChanged = () => {
    if (!gridApi) return;
    const sortModel = gridApi.getSortModel ? gridApi.getSortModel() : [];
    console.log("Sort Model: ", sortModel);
  };

  const exportCsv = () => {
    gridApi.exportDataAsCsv();
  };

  const calculateAverages = (data, numericFields) => {
    const averages = {};
    numericFields.forEach((field) => {
      const values = data.map((row) => parseFloat(row[field])).filter((value) => !isNaN(value));

      if (values.length > 0) {
        averages[field] = (values.reduce((sum, val) => sum + val, 0) / values.length).toFixed(2);
      } else {
        averages[field] = "N/A";
      }
    });
    setAverages(averages);
  };

  useEffect(() => {
    if (routeData.length > 0) {
      const numericFields = ["fuelEconomy", "currentGas", "routeLength", "totalCost", "totalGas", "IFTA", "TTGHG"];
      calculateAverages(routeData, numericFields);
    }
  }, [routeData]);

  const footerRowData = {
    startAddress: "Average",
    fuelEconomy: parseFloat(averages.fuelEconomy),
    currentGas: parseFloat(averages.currentGas),
    routeLength: parseFloat(averages.routeLength),
    totalCost: parseFloat(averages.totalCost),
    totalGas: parseFloat(averages.totalGas),
    IFTA: parseFloat(averages.IFTA),
    TTGHG: parseFloat(averages.TTGHG),
    isFooter: true,
  };

  return (
    <div>
      <div
        className="ag-theme-alpine"
        style={{
          height: tableHeight,
          width: "98%",
          margin: "auto",
          marginTop: "-35px",
          ...myTheme,
        }}
      >
        <AgGridReact rowData={routeData} columnDefs={columnDefs} onGridReady={onGridReady} rowSelection="multiple" getRowClass={(params) => (params.data && params.data.isFooter ? "footer-row" : "")} onSelectionChanged={onSelectionChanged} onSortChanged={onSortChanged} domLayout="normal" enableColResize={true} pagination={true} paginationPageSize={20} pinnedBottomRowData={index != 0 && routeData.length > 0 ? [footerRowData] : []} isRowSelectable={(node) => !node.data?.isFooter} />

        <style jsx>{`
          .ag-theme-alpine {
            scrollbar-width: thin;
            scrollbar-color: #d15533 transparent;
          }

          .ag-theme-alpine::-webkit-scrollbar {
            width: 6px;
            height: 6px;
          }

          .ag-theme-alpine::-webkit-scrollbar-track {
            background-color: transparent;
            border-radius: 10px;
          }

          .ag-theme-alpine::-webkit-scrollbar-thumb {
            background-color: #d15533;
            border-radius: 10px;
            border: 2px solid transparent;
          }

          .ag-theme-alpine::-webkit-scrollbar-thumb:hover {
            background-color: #b44c2f;
          }

          .ag-icon {
            color: lightgrey !important;
          }

          .ag-icon:hover {
            color: #0d6efd !important;
          }

          .ag-header-cell-sortable .ag-header-icon {
            color: white !important;
          }

          .ag-filter-icon {
            color: white !important;
          }

          .ag-theme-alpine .ag-column-select-checkbox {
            color: white !important;
          }

          .footer-row {
            pointer-events: none;
            background-color: #f0f0f0;
          }
        `}</style>
      </div>
      <GridActions routeData={routeData} index={index} onExport={exportCsv} handleCalculateSelection={handleCalculateSelection} handleApproveRoute={handleApproveRoute} selectedRoute={selectedRoute} selectedRows={gridApi ? gridApi.getSelectedRows() : []} handleDuplicateRoutes={handleDuplicateRoutes} handleUpdateRouteAttributes={handleUpdateRouteAttributes} handleCustomGoogleLinkUpdate={handleCustomGoogleLinkUpdate} unassignRoute={unassignRoute} />
      {(index === 1 || index === 2) && selectedRoute && <ViewRoutes calculatedRoute={selectedRoute} handleApproveRoute={handleApproveRoute} />}
    </div>
  );
};

export default RouteGrid;
