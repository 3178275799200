import axios from "axios";
import { serverConfig } from "./serverConfig";

export const sendSms = async (requestData) => {
  const options = {
    method: "POST",
    url: `${serverConfig.serverAddress}/message/send`,
    data: requestData,
    headers: {
      "Content-Type": "application/json",
      "api-key": serverConfig.apiKey,
    },
  };

  try {
    const response = await axios(options);

    return {
      status: response.status,
      message: response.data.message || response.statusText,
    };
  } catch (error) {
    return {
      status: error.response?.status || 500,
      message: error.response?.data?.message || error.response?.statusText,
    };
  }
};
