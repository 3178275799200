import React, { useState, useEffect } from "react";
import { createCorridor, editCorridor, deleteCorridor, getAllCorridors } from "../helpers/corridorHelper";
import ConfirmModal from "../shared/ConfirmModal";
import { FaPlus, FaEdit, FaTrash } from "react-icons/fa";
import styles from "./RoutePlanning.module.css";

const RoutePlanning = () => {
  const [corridors, setCorridors] = useState([]);
  const [editing, setEditing] = useState(null);
  const [newCorridor, setNewCorridor] = useState({ name: "", link: "" });
  const [editData, setEditData] = useState({});
  const [showForm, setShowForm] = useState(false);
  const [message, setMessage] = useState("");

  useEffect(() => {
    const fetchCorridors = async () => {
      const response = await getAllCorridors();
      if (response.status === 200) {
        setCorridors(response.data.corridors);
      } else {
        alert("Failed to fetch corridors");
      }
    };
    fetchCorridors();
  }, []);

  const handleCreate = async () => {
    const response = await createCorridor(newCorridor);
    if (response.status === 200) {
      setMessage("Corridor created successfully!");
      setNewCorridor({ name: "", link: "" });
      setShowForm(false);
      fetchCorridors();
    } else {
      alert("Failed to create corridor");
    }
  };

  const handleEdit = (corridor) => {
    setEditing(corridor.corridorId);
    setEditData({ ...corridor });
  };

  const handleSave = async () => {
    const response = await editCorridor(editing, editData);
    if (response.status === 200) {
      setMessage("Corridor updated successfully!");
      setCorridors(corridors.map((corridor) => (corridor.corridorId === editing ? response.data : corridor)));
      setEditing(null);
      fetchCorridors();
    } else {
      alert("Failed to edit corridor");
    }
  };

  const handleDelete = async (corridorId) => {
    const response = await deleteCorridor(corridorId);
    if (response.status === 200) {
      setMessage("Corridor deleted successfully!");
      fetchCorridors();
    } else {
      alert("Failed to delete corridor");
    }
  };

  const fetchCorridors = async () => {
    const response = await getAllCorridors();
    if (response.status === 200) {
      setCorridors(response.data.corridors);
    } else {
      alert("Failed to fetch corridors");
    }
  };

  const deleteIcon = () => <FaTrash color="#d15533" size="20px" />;

  return (
    <div className={styles.routePlanningContainer}>
      <div className={styles.title}>
        Route Planning
        <button className={styles.addButton} onClick={() => setShowForm(!showForm)}>
          <FaPlus style={{ marginRight: "5px" }} /> Add New Corridor
        </button>
      </div>
      {showForm && (
        <div className={styles.section}>
          <div className={styles.field}>
            <label className={styles.label}>Corridor Name</label>
            <input className={styles.input} type="text" value={newCorridor.name} onChange={(e) => setNewCorridor({ ...newCorridor, name: e.target.value })} />
          </div>
          <div className={styles.field}>
            <label className={styles.label}>Link</label>
            <input className={styles.input} type="text" value={newCorridor.link} onChange={(e) => setNewCorridor({ ...newCorridor, link: e.target.value })} />
          </div>
          <div className={styles.actions}>
            <button className={styles.button} onClick={handleCreate}>
              Create Corridor
            </button>
          </div>
        </div>
      )}
      <div className={styles.divider}></div>
      <div className={styles.corridorDetailsHeader}>
        <div>Corridor Name</div>
        <div>Link</div>
      </div>
      <div className={styles.detailsContainer}>
        {corridors.map((corridor) => (
          <div key={corridor.corridorId} className={styles.corridorItem}>
            {editing === corridor.corridorId ? (
              <>
                <div>
                  <input className={styles.input} type="text" value={editData.name} onChange={(e) => setEditData({ ...editData, name: e.target.value })} />
                </div>
                <div>
                  <input className={styles.input} type="text" value={editData.link} onChange={(e) => setEditData({ ...editData, link: e.target.value })} />
                </div>
                <div className={styles.actions}>
                  <button className={styles.button} onClick={handleSave}>
                    Save
                  </button>
                  <button className={styles.button} onClick={() => setEditing(null)}>
                    Cancel
                  </button>
                </div>
              </>
            ) : (
              <>
                <div className={styles.corridorDetails}>
                  <div>{corridor.name}</div>
                  <div>{corridor.link}</div>
                </div>
                <div className={styles.actions}>
                  <button className={styles.iconButton} onClick={() => handleEdit(corridor)}>
                    <FaEdit color="#d15533" size="20px" />
                  </button>
                  <ConfirmModal title="Delete" text="Are you sure you want to delete this corridor?" onConfirm={() => handleDelete(corridor.corridorId)} iconButton={deleteIcon} />
                </div>
              </>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default RoutePlanning;
