// Function to get Organization ID from local storage
export const getOrgId = () => {
  const organizationInfoString = localStorage.getItem("organizationInfo");
  if (organizationInfoString) {
    try {
      const organizationInfo = JSON.parse(organizationInfoString);
      return organizationInfo.organizationId;
    } catch (error) {
      return {
        status: 400,
        message: "Error parsing organizationInfo from local storage",
      };
    }
  } else {
    return {
      status: 400,
      message: "Organization ID is missing from local storage",
    };
  }
};
